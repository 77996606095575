import {Campaign} from '../models/campaign';
import {AudienceDefinition} from '../models/audience-definition';
import {ContentBundleFactory} from '../../content-bundle/services/content-bundle-factory';
import {ContentBundle} from '../../content-bundle/content-bundle';
import {Injectable} from '@angular/core';
import {CampaignService} from './campaign.service';
import {ContentBundleService} from '../../content-bundle/services/content-bundle.service';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignFactoryService {

  constructor(private campaignService: CampaignService, private contentBundleService: ContentBundleService) {
  }

  public create(campaign: Campaign): Observable<Campaign> {
    return this.campaignService.create(campaign).pipe(
      switchMap((createdCampaign: Campaign) => {
        campaign.content_bundle.id = createdCampaign.content_bundle_id;
        return this.contentBundleService.update(campaign.content_bundle).pipe(
          withLatestFrom(of(createdCampaign))
        );
      }),
      map(([updatedContentBundle, createdCampaign]) => {
        createdCampaign.content_bundle = updatedContentBundle;
        return createdCampaign;
      })
    );
  }


  public static buildCampaign(attributes: Pick<Campaign, 'campaign_goal' | 'content_bundle'>): Campaign {
    return new Campaign({
      status: 'draft',
      audience_definition: new AudienceDefinition({}),
      content_bundle: new ContentBundle({}),
      ...attributes
    });
  }

  public static buildInformationCampaign(): Campaign {
    return this.buildCampaign({
      campaign_goal: 'information',
      content_bundle: ContentBundleFactory.buildForInformationCampaign()
    });
  }

  public static buildDealCampaign(): Campaign {
    return this.buildCampaign({
      campaign_goal: 'deal',
      content_bundle: ContentBundleFactory.buildForDealCampaign()
    });
  }

  public static buildInformationWithSpecialPointsEventCampaign(): Campaign {
    return this.buildCampaign({
      campaign_goal: 'information',
      content_bundle: ContentBundleFactory.buildForInformationWithSpecialPointsEventCampaign()
    });
  }

  public static buildReferralCampaign(): Campaign {
    return this.buildCampaign({
      campaign_goal: 'referral',
      content_bundle: ContentBundleFactory.buildForReferralCampaign()
    });
  }

  public static buildObtainReviewCampaign(): Campaign {
    const campaign = this.buildCampaign({
      campaign_goal: 'information',
      content_bundle: ContentBundleFactory.buildForObtainReviewCampaign()
    });
    campaign.audience_definition.segment = 'partner_obtain_reviews';
    return campaign;
  }
}
