import {Component, Input, OnInit} from '@angular/core';
import {SpecialPointsEvent} from '../special-points-event';
import {Partner} from '../../partner/partner';

@Component({
  selector: 'app-special-points-event-preview',
  templateUrl: './special-points-event-preview.component.html',
  styleUrls: ['./special-points-event-preview.component.scss']
})
export class SpecialPointsEventPreviewComponent {
  private weekDaysLabel = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

  @Input()
  specialPointsEvent: SpecialPointsEvent;

  constructor() {
  }

  isSingleDayEvent() {
    const re = this.specialPointsEvent.recurring_events[0];
    return re.starts_at.getFullYear() === re.ends_at.getFullYear()
      && re.starts_at.getMonth() === re.ends_at.getMonth()
      && re.starts_at.getDate() === re.ends_at.getDate();
  }

  get recurring_event() {
    return !!this.specialPointsEvent.recurring_events ? this.specialPointsEvent.recurring_events[0] : null;
  }

  getWeekdays() {
    if (this.recurring_event.rule !== 'weekly') {
      return '';
    }
    // map from Date object to week day index:
    const weekDayIndices = this.specialPointsEvent.recurring_events
      .map((re) => re.starts_at.getDay()) // So = 0, Mo = 1... Sa = 6
      .map((re) => re === 0 ? 6 : (re - 1)) // set So = 7 and subtract 1  to match indices in weekDaysLabel
      .sort();

    // check if all days are consecutive:
    const consecutiveDays = weekDayIndices
      .slice(1) // slice the first element of array so that ...
      .map((v, i) => v - weekDayIndices[i]) // ... the index (i) is always one element behind the value (v)
      .every((v) => v === 1); // check if the difference is always 1

    // print consecutive days when possible:
    if (consecutiveDays && weekDayIndices.length > 2) {
      return this.weekDaysLabel[weekDayIndices[0]] + ' bis ' + this.weekDaysLabel[weekDayIndices[weekDayIndices.length - 1]];
    }

    // print list of week days:
    if (weekDayIndices.length > 1) {
      return weekDayIndices.slice(0, -1).map((x) => {
        return this.weekDaysLabel[x];
      }).join(', ') + ' und ' + this.weekDaysLabel[weekDayIndices[weekDayIndices.length - 1]];
    } else {
      return this.weekDaysLabel[weekDayIndices[0]];
    }
  }

}
