export class Modal {
  type = '';
  title = '';
  content = '';
  buttonText = 'Ok';
  buttonClass = 'btn-primary';
  onClose: () => any;
  onOk: () => any;

  constructor(values: Object = {}) {
    Object.assign(this as any, values);
  }
}
