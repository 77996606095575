import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {WallpaperComponent} from './wallpaper.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  providers: [],
  declarations: [WallpaperComponent],
  exports: [WallpaperComponent]
})
export class WallpaperModule {
}
