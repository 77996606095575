import {Component, Input, OnInit} from '@angular/core';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input()
  ce: ContentElement;

  @Input()
  label: string;

  @Input()
  url: string;

  constructor() {
  }

  getLabel() {
    if (this.ce) {
      return this.ce.data.text;
    } else {
      return this.label;
    }
  }

  getUrl() {
    if (this.ce) {
      return this.ce?.data?.link || 'https://example.com';
    } else {
      return this.url;
    }
  }

}
