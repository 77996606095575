import {Component, OnInit} from '@angular/core';
import {Contract} from '../contract';
import {ContractService} from '../contract.service';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html'
})

export class ContractListComponent implements OnInit {
  contracts: Array<Contract> = [];
  count = 0;
  page = 1;
  per = 10;
  order_field = 'id';
  order_direction = 'desc';

  constructor(private contractService: ContractService) {
  }

  ngOnInit() {
    this.onPage(1);
  }

  onPage(page: number) {
    this.page = page;
    this.refreshData();
  }

  onOrder(field: string) {
    if (this.order_field == field) {
      this.order_direction = this.order_direction == 'desc' ? 'asc' : 'desc';
    } else {
      this.order_field = field;
    }
    this.refreshData();
  }

  refreshData() {
    this.contractService.fetchPage(this.page, this.per, this.order_field, this.order_direction).subscribe(contractList => {
      this.contracts = contractList;
      this.count = contractList.length;
    });
  }

  downloadPDF(contract) {
    this.contractService.downloadPDF(contract).subscribe((blob) => {
      // Create a link element, hide it, direct
      // it towards the blob, and then 'click' it programatically
      const a = document.createElement('a');
      // a.style = 'display: none';
      document.body.appendChild(a);
      // Create a DOMString representing the blob
      // and point the link element towards it
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = contract.file_name;
      // programatically click the link to trigger the download
      a.click();
      // release the reference to the file by revoking the Object URL
      URL.revokeObjectURL(url);
      a.remove();
    });
  }
}
