<div class="form-container" [class.highlighted]="highlight" (click)="onTurnRequest.emit(prize.level)">
  <div class="form-settings">
    <div class="form-heading">
      <h2 class="type-title">{{ title }}</h2>
      <div class="wheel-image">
        <app-wheel-image [highlight]="prize.level"></app-wheel-image>
      </div>
    </div>
    <form #form="ngForm" [name]="prize.level">
      <div class="mx-0">
        <div class="form-group col-md-5">
          <label for="{{prize.level}}-prize_type">Preis wählen:</label>
          <select id="{{prize.level}}-prize_type" name="prize_type" class="form-control" [(ngModel)]="prize.type" (ngModelChange)="onChange()">
            <option [value]="'points_multiplier_event'" *ngIf="prize.level !== 'consolation'">Punkte-Multiplikator</option>
            <option [value]="'extra_points_event'">Extra-Punkte</option>
            <option [value]="'reward'" *ngIf="prize.level === 'jackpot'">Sachpreis</option>
          </select>
        </div>
      </div>
      <div class="mx-0">

        <!-- multiplier form -->
        <div class="form-group col-md-12" *ngIf="prize.type === 'points_multiplier_event'">
          <div ngbRadioGroup class="btn-group-toggle" [name]="'multiplier'" data-toggle="buttons">
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 2">
              <input name="multiplier" ngbButton type="radio" [value]="2" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">2
            </label>
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 3">
              <input name="multiplier" ngbButton type="radio" [value]="3" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">3
            </label>
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 4">
              <input name="multiplier" ngbButton type="radio" [value]="4" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">4
            </label>
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 5">
              <input name="multiplier" ngbButton type="radio" [value]="5" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">5
            </label>
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 6">
              <input name="multiplier" ngbButton type="radio" [value]="6" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">6
            </label>
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 7">
              <input name="multiplier" ngbButton type="radio" [value]="7" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">7
            </label>
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 8">
              <input name="multiplier" ngbButton type="radio" [value]="8" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">8
            </label>
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 9">
              <input name="multiplier" ngbButton type="radio" [value]="9" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">9
            </label>
            <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="multiplierPointsEvent.multiplier === 10">
              <input name="multiplier" ngbButton type="radio" [value]="10" [(ngModel)]="multiplierPointsEvent.multiplier" (ngModelChange)="onChange()">10
            </label>
          </div>
        </div>

        <!-- extra_points form -->
        <div class="form-group col-md-12" *ngIf="prize.type === 'extra_points_event'">
          <div class="form-inline">
            <input name="extra_points" type="number" step="1" min="5" max="9999" [defaultValue]="5" class="form-control mr-1" style="width: 5em;"
                   placeholder="10" [(ngModel)]="extraPointsEvent.extra_points" (ngModelChange)="onChange()"/>
            <span class="mr-1">Extra-Punkte auf den Einkauf</span>
          </div>
        </div>


        <!-- material prize -->
        <div class="form-group col-md-12" *ngIf="prize.type === 'reward'">
          <div class="form-inline">
            <label for="material_prize">Beschreibung des Sachpreises:</label>
            <span class="remaining-text-length text-muted">noch {{ maxTextLength - reward.title?.length }} Zeichen</span>
            <textarea class="form-control" id="material_prize" name="material_prize" placeholder="Kurze Beschreibung des Hauptgewinns. Z.B. Ein Frühstück für zwei"
                      [(ngModel)]="reward.title" [maxLength]="maxTextLength" (blur)="onChange()"></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="form-summary" [class.has-danger]="invalidOdds">
    Gewinn-Wahrscheinlichkeit
    <div class="input-group">
      <input type="number" class="form-control" [(ngModel)]="prize.odds" min="0.5" max="99" step="0.5" (ngModelChange)="onChange()" [disabled]="prize.level === 'consolation'"/>
      <div class="input-group-append">
        <div class="input-group-text">%</div>
      </div>
    </div>
    <ng-container *ngIf="prize.level !== 'consolation'"> (ca. jeder {{ 100 / prize.odds | number:"1.0-0" }}. Kunde gewinnt diesen Preis)</ng-container>
  </div>
</div>
