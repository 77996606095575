import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-trix-text',
  templateUrl: './trix-text.component.html',
  styleUrls: ['./trix-text.component.scss']
})
export class TrixTextComponent {

  @Input()
  text: string;

  constructor() {
  }
}
