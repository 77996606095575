export class ThirdPartyBusiness {
  id: number;
  name: string;
  unique_name: string;
  new_review_url: string;
  address: string;
  website_url: string;
  maps_url: string;
  status: 'ok' | 'discontinued';
  business_id: number;
  o_auth_token_id: number;

  constructor(o: Partial<ThirdPartyBusiness> = {}) {
    Object.assign(this as any, o);
  }
}
