<div class="prize-box">
  <h1>Glückwunsch!</h1>
  <app-prize-description [type]="winning.object_type === 'Reward' ? 'reward' : $any(winning.object).type" [object]="winning.object"></app-prize-description>

  <button class="btn btn-primary next-button" (click)="nextSubject?.emit()" *ngIf="winning.object_type !== 'Reward'">
    Weiter
  </button>

  <button class="btn btn-primary next-button" (click)="nextSubject?.emit()" *ngIf="winning.object_type === 'Reward'">
    <i class="fa fa-arrow-right"></i> Bitte iPad zum Kassierer kippen.
  </button>

</div>

<svg class="background" *ngIf="theme === 'light-theme'" width="2048px" height="1536px" viewBox="0 0 2048 1536" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <title>Mankido Glücksrad Alpha v1 Copy 30</title>
  <g id="Mankido-Glücksrad-Alpha-v1-Copy-30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect fill="#FFFFFF" x="0" y="0" width="2048" height="1536"></rect>
    <path d="M230.299502,746.270717 C267.765787,1037.83797 852.177455,1613.93998 1384.80807,1273.31604 C1621.6266,1121.86764 1847.03927,785.303804 1816.97762,572.396972 C1779.42735,306.453172 1426.96332,175.834472 1164.96106,175.834472 C693.207454,175.834472 192.833216,454.703461 230.299502,746.270717 Z" id="Path-3-Copy" fill="#E3F6FF"></path>
    <g id="Group-3" transform="translate(1044.500000, 762.000000) rotate(-180.000000) translate(-1044.500000, -762.000000) translate(92.000000, -11.500000)">
      <path d="M720.736282,120.103456 L756.456768,120.103456 C758.113622,120.103456 759.456768,121.446602 759.456768,123.103456 C759.456768,123.527892 759.366706,123.947497 759.192533,124.33455 L741.33229,164.023979 C740.652375,165.5349 738.876352,166.208564 737.365431,165.52865 C736.696868,165.227796 736.161614,164.692542 735.860761,164.023979 L718.000517,124.33455 C717.320603,122.823629 717.994267,121.047606 719.505188,120.367691 C719.89224,120.193518 720.311846,120.103456 720.736282,120.103456 Z" id="Path-11-Copy-8" fill="#FFD700" transform="translate(738.596525, 145.103456) scale(1, -1) rotate(-119.000000) translate(-738.596525, -145.103456) "></path>
      <path d="M319.649678,1285.51808 L378.020488,1285.51808 C379.677342,1285.51808 381.020488,1286.86122 381.020488,1288.51808 C381.020488,1289.19028 380.794731,1289.843 380.379424,1290.37156 L351.194019,1327.51586 C350.170366,1328.81866 348.284399,1329.04496 346.981595,1328.0213 C346.79357,1327.87357 346.623883,1327.70388 346.476147,1327.51586 L317.290741,1290.37156 C316.267088,1289.06876 316.493385,1287.18279 317.796189,1286.15914 C318.32475,1285.74383 318.977476,1285.51808 319.649678,1285.51808 Z" id="Path-11-Copy-8" fill="#D07FA6" transform="translate(348.835083, 1308.018076) rotate(-108.000000) translate(-348.835083, -1308.018076) "></path>
      <path d="M1466.316,579.213527 L1498.97105,579.213527 C1500.62791,579.213527 1501.97105,580.556673 1501.97105,582.213527 C1501.97105,582.885729 1501.7453,583.538455 1501.32999,584.067015 L1485.00246,604.847077 C1483.97881,606.149881 1482.09284,606.376178 1480.79004,605.352525 C1480.60201,605.204788 1480.43233,605.035101 1480.28459,604.847077 L1463.95706,584.067015 C1462.93341,582.764211 1463.1597,580.878244 1464.46251,579.854591 C1464.99107,579.439284 1465.64379,579.213527 1466.316,579.213527 Z" id="Path-11-Copy-8" fill="#63E3E8" transform="translate(1482.643525, 593.531412) rotate(-48.000000) translate(-1482.643525, -593.531412) "></path>
      <path d="M1745.0806,283.5 L1777.73566,283.5 C1779.39252,283.5 1780.73566,284.843146 1780.73566,286.5 C1780.73566,287.172202 1780.5099,287.824928 1780.0946,288.353488 L1763.76707,309.13355 C1762.74342,310.436354 1760.85745,310.662651 1759.55464,309.638998 C1759.36662,309.491261 1759.19693,309.321574 1759.0492,309.13355 L1742.72167,288.353488 C1741.69801,287.050684 1741.92431,285.164717 1743.22711,284.141064 C1743.75568,283.725757 1744.4084,283.5 1745.0806,283.5 Z" id="Path-11-Copy-8" fill="#34C39C"></path>
      <path d="M256.645675,378.908859 L289.300733,378.908859 C290.957588,378.908859 292.300733,380.252005 292.300733,381.908859 C292.300733,382.581061 292.074976,383.233787 291.65967,383.762347 L275.33214,404.542408 C274.308487,405.845213 272.42252,406.07151 271.119716,405.047856 C270.931691,404.90012 270.762004,404.730433 270.614268,404.542408 L254.286738,383.762347 C253.263085,382.459543 253.489382,380.573576 254.792186,379.549922 C255.320747,379.134616 255.973473,378.908859 256.645675,378.908859 Z" id="Path-11-Copy-8" fill="#5B439B" transform="translate(272.973204, 393.226744) rotate(56.000000) translate(-272.973204, -393.226744) "></path>
      <path d="M1346.38425,1435.11475 L1379.03931,1435.11475 C1380.69616,1435.11475 1382.03931,1436.4579 1382.03931,1438.11475 C1382.03931,1438.78695 1381.81355,1439.43968 1381.39824,1439.96824 L1365.07071,1460.7483 C1364.04706,1462.0511 1362.16109,1462.2774 1360.85829,1461.25375 C1360.67026,1461.10601 1360.50058,1460.93632 1360.35284,1460.7483 L1344.02531,1439.96824 C1343.00166,1438.66543 1343.22795,1436.77947 1344.53076,1435.75581 C1345.05932,1435.34051 1345.71204,1435.11475 1346.38425,1435.11475 Z" id="Path-11-Copy-9" fill="#5B439B" transform="translate(1362.711776, 1449.432635) rotate(56.000000) translate(-1362.711776, -1449.432635) "></path>
      <path d="M1542.83995,228.051728 C1542.83995,228.051728 1590.7616,205.551728 1645.36281,228.051728" id="Path-12-Copy-4" stroke="#FFD700" stroke-width="15" transform="translate(1594.101379, 223.051728) rotate(-53.000000) translate(-1594.101379, -223.051728) "></path>
      <path d="M1,556.729558 C1,556.729558 48.9216531,534.229558 103.522857,556.729558" id="Path-12-Copy-4" stroke="#B766FF" stroke-width="15" transform="translate(52.261428, 551.729558) rotate(41.000000) translate(-52.261428, -551.729558) "></path>
      <path d="M1585.25016,1315.56875 C1585.25016,1315.56875 1633.17181,1293.06875 1687.77301,1315.56875" id="Path-12-Copy-8" stroke="#34C39C" stroke-width="15" transform="translate(1636.511586, 1310.568752) rotate(-29.000000) translate(-1636.511586, -1310.568752) "></path>
      <circle id="Oval-Copy-25" stroke="#FF86DF" stroke-width="5" cx="1858" cy="664" r="25"></circle>
      <circle id="Oval-Copy-25" stroke="#B766FF" stroke-width="5" cx="1290.7735" cy="154.103456" r="25"></circle>
      <circle id="Oval-Copy-25" stroke="#5B439B" stroke-width="5" cx="647.060204" cy="1500.21703" r="15"></circle>
      <circle id="Oval-Copy-26" stroke="#B766FF" stroke-width="15" cx="364.175044" cy="52" r="33"></circle>
      <circle id="Oval-Copy-25" stroke="#00C69A" stroke-width="7" cx="110.729215" cy="283.5" r="13"></circle>
      <circle id="Oval-Copy-29" stroke="#00C69A" stroke-width="7" cx="905.611652" cy="1264.84362" r="13"></circle>
      <circle id="Oval-Copy-25" fill="#5B439B" cx="1858" cy="1409.56875" r="12.5687521"></circle>
      <circle id="Oval-Copy-27" fill="#5B439B" cx="693.254064" cy="1189.06875" r="12.5687521"></circle>
      <circle id="Oval-Copy-25" fill="#34C39C" cx="139.797967" cy="1189.06875" r="12.5687521"></circle>
      <g id="Group-Copy-3" transform="translate(1738.908132, 443.226744) rotate(-315.000000) translate(-1738.908132, -443.226744) translate(1724.408132, 428.726744)" fill="#FFD700">
        <circle id="Oval-Copy-10" cx="3.47136963" cy="3.47136963" r="3.47136963"></circle>
        <circle id="Oval-Copy-13" cx="3.47136963" cy="14.4457995" r="3.47136963"></circle>
        <circle id="Oval-Copy-11" cx="14.272488" cy="3.47136963" r="3.47136963"></circle>
        <circle id="Oval-Copy-14" cx="14.272488" cy="14.4457995" r="3.47136963"></circle>
        <circle id="Oval-Copy-12" cx="25.0439165" cy="3.47136963" r="3.47136963"></circle>
        <circle id="Oval-Copy-15" cx="25.0439165" cy="14.4457995" r="3.47136963"></circle>
        <circle id="Oval-Copy-18" cx="3.47136963" cy="25.4934186" r="3.47136963"></circle>
        <circle id="Oval-Copy-17" cx="14.272488" cy="25.4934186" r="3.47136963"></circle>
        <circle id="Oval-Copy-16" cx="25.0439165" cy="25.4934186" r="3.47136963"></circle>
      </g>
      <g id="Group-Copy-4" transform="translate(1145.611231, 1215.449345) rotate(-270.000000) translate(-1145.611231, -1215.449345) translate(1131.111231, 1200.949345)" fill="#FFD700">
        <circle id="Oval-Copy-10" cx="3.47136963" cy="3.47136963" r="3.47136963"></circle>
        <circle id="Oval-Copy-13" cx="3.47136963" cy="14.4457995" r="3.47136963"></circle>
        <circle id="Oval-Copy-11" cx="14.272488" cy="3.47136963" r="3.47136963"></circle>
        <circle id="Oval-Copy-14" cx="14.272488" cy="14.4457995" r="3.47136963"></circle>
        <circle id="Oval-Copy-12" cx="25.0439165" cy="3.47136963" r="3.47136963"></circle>
        <circle id="Oval-Copy-15" cx="25.0439165" cy="14.4457995" r="3.47136963"></circle>
        <circle id="Oval-Copy-18" cx="3.47136963" cy="25.4934186" r="3.47136963"></circle>
        <circle id="Oval-Copy-17" cx="14.272488" cy="25.4934186" r="3.47136963"></circle>
        <circle id="Oval-Copy-16" cx="25.0439165" cy="25.4934186" r="3.47136963"></circle>
      </g>
      <g id="Group-Copy-3" transform="translate(509.500000, 234.000000)" fill="#FFD700">
        <circle id="Oval-Copy-10" cx="3.95017923" cy="3.95017923" r="3.95017923"></circle>
        <circle id="Oval-Copy-13" cx="3.95017923" cy="16.4383236" r="3.95017923"></circle>
        <circle id="Oval-Copy-11" cx="16.241107" cy="3.95017923" r="3.95017923"></circle>
        <circle id="Oval-Copy-14" cx="16.241107" cy="16.4383236" r="3.95017923"></circle>
        <circle id="Oval-Copy-12" cx="28.4982498" cy="3.95017923" r="3.95017923"></circle>
        <circle id="Oval-Copy-15" cx="28.4982498" cy="16.4383236" r="3.95017923"></circle>
        <circle id="Oval-Copy-18" cx="3.95017923" cy="29.0097521" r="3.95017923"></circle>
        <circle id="Oval-Copy-17" cx="16.241107" cy="29.0097521" r="3.95017923"></circle>
        <circle id="Oval-Copy-16" cx="28.4982498" cy="29.0097521" r="3.95017923"></circle>
      </g>
      <g id="Group-Copy-3" transform="translate(1570.766855, 1523.551549) rotate(-45.000000) translate(-1570.766855, -1523.551549) translate(1554.266855, 1507.051549)" fill="#FFD700">
        <circle id="Oval-Copy-10" cx="3.95017923" cy="3.95017923" r="3.95017923"></circle>
        <circle id="Oval-Copy-13" cx="3.95017923" cy="16.4383236" r="3.95017923"></circle>
        <circle id="Oval-Copy-11" cx="16.241107" cy="3.95017923" r="3.95017923"></circle>
        <circle id="Oval-Copy-14" cx="16.241107" cy="16.4383236" r="3.95017923"></circle>
        <circle id="Oval-Copy-12" cx="28.4982498" cy="3.95017923" r="3.95017923"></circle>
        <circle id="Oval-Copy-15" cx="28.4982498" cy="16.4383236" r="3.95017923"></circle>
        <circle id="Oval-Copy-17" cx="16.241107" cy="29.0097521" r="3.95017923"></circle>
        <circle id="Oval-Copy-16" cx="28.4982498" cy="29.0097521" r="3.95017923"></circle>
      </g>
      <circle id="Oval-Copy-25" fill="#FF59A9" cx="164.935471" cy="1472.64827" r="12.5687521"></circle>
      <polyline id="Path-11-Copy-15" stroke="#34C39C" stroke-width="15" points="53.3380144 949.602587 78.3380144 969.602587 103.338014 949.602587 128.338014 969.602587 153.338014 949.602587 178.338014 969.602587"></polyline>
      <polyline id="Path-11-Copy-15" stroke="#FFD700" stroke-width="15" transform="translate(1845.431248, 1105.500000) rotate(-30.000000) translate(-1845.431248, -1105.500000) " points="1782.93125 1095.5 1807.93125 1115.5 1832.93125 1095.5 1857.93125 1115.5 1882.93125 1095.5 1907.93125 1115.5"></polyline>
      <polyline id="Path-11-Copy-15" stroke="#FF86DF" stroke-width="5" transform="translate(956.876894, 52.000000) rotate(-315.000000) translate(-956.876894, -52.000000) " points="894.376894 42 919.376894 62 944.376894 42 969.376894 62 994.376894 42 1019.37689 62"></polyline>
      <rect id="Rectangle-Copy-40" fill="#5B439B" transform="translate(52.261428, 89.265242) rotate(39.000000) translate(-52.261428, -89.265242) " x="38.2614283" y="75.2652416" width="28" height="28" rx="5"></rect>
      <rect id="Rectangle-Copy-40" fill="#BAD8FF" transform="translate(18.570899, 1397.000000) rotate(72.000000) translate(-18.570899, -1397.000000) " x="4.57089939" y="1383" width="28" height="28" rx="5"></rect>
      <path d="M339.858204,1505.21703 C339.858204,1505.21703 387.779858,1482.71703 442.381061,1505.21703" id="Path-12-Copy-8" stroke="#BAD8FF" stroke-width="15" transform="translate(391.119633, 1500.217025) rotate(-52.000000) translate(-391.119633, -1500.217025) "></path>
      <rect id="Rectangle-Copy-40" fill="#FF59A9" x="1856.56875" y="165.461343" width="28" height="28" rx="5"></rect>
      <circle id="Oval-Copy-25" fill="#34C39C" cx="1048.56875" cy="1497.78578" r="12.5687521"></circle>
      <circle id="Oval-Copy-25" stroke="#FFD700" stroke-width="5" cx="1603.94445" cy="745.220035" r="25"></circle>
      <circle id="Oval-Copy-25" fill="#34C39C" cx="297.425109" cy="782.788788" r="12.5687521"></circle>
      <path d="M1571.98822,1010.78995 L1604.64328,1010.78995 C1606.30013,1010.78995 1607.64328,1012.13309 1607.64328,1013.78995 C1607.64328,1014.46215 1607.41752,1015.11487 1607.00222,1015.64344 L1590.67469,1036.4235 C1589.65103,1037.7263 1587.76507,1037.9526 1586.46226,1036.92894 C1586.27424,1036.78121 1586.10455,1036.61152 1585.95681,1036.4235 L1569.62928,1015.64344 C1568.60563,1014.34063 1568.83193,1012.45466 1570.13473,1011.43101 C1570.66329,1011.0157 1571.31602,1010.78995 1571.98822,1010.78995 Z" id="Path-11-Copy-14" fill="#5B439B" transform="translate(1588.315750, 1025.107832) rotate(-315.000000) translate(-1588.315750, -1025.107832) "></path>
      <g id="Group-Copy-3" transform="translate(849.143525, 1421.388822) rotate(-315.000000) translate(-849.143525, -1421.388822) translate(824.143525, 1396.388822)" fill="#FFD700">
        <circle id="Oval-Copy-10" cx="5.98512005" cy="5.98512005" r="5.98512005"></circle>
        <circle id="Oval-Copy-13" cx="5.98512005" cy="24.9065509" r="5.98512005"></circle>
        <circle id="Oval-Copy-11" cx="24.6077378" cy="5.98512005" r="5.98512005"></circle>
        <circle id="Oval-Copy-14" cx="24.6077378" cy="24.9065509" r="5.98512005"></circle>
        <circle id="Oval-Copy-12" cx="43.1791664" cy="5.98512005" r="5.98512005"></circle>
        <circle id="Oval-Copy-15" cx="43.1791664" cy="24.9065509" r="5.98512005"></circle>
        <circle id="Oval-Copy-18" cx="5.98512005" cy="43.9541699" r="5.98512005"></circle>
        <circle id="Oval-Copy-17" cx="24.6077378" cy="43.9541699" r="5.98512005"></circle>
        <circle id="Oval-Copy-16" cx="43.1791664" cy="43.9541699" r="5.98512005"></circle>
      </g>
      <polyline id="Path-11-Copy-14" stroke="#B766FF" stroke-width="5" transform="translate(1786.304660, 879.671635) rotate(-345.000000) translate(-1786.304660, -879.671635) " points="1723.80466 869.671635 1748.80466 889.671635 1773.80466 869.671635 1798.80466 889.671635 1823.80466 869.671635 1848.80466 889.671635"></polyline>
      <rect id="Rectangle-Copy-40" fill="#5B439B" transform="translate(304.655347, 1085.388736) rotate(-315.000000) translate(-304.655347, -1085.388736) " x="290.655347" y="1071.38874" width="28" height="28" rx="5"></rect>
      <circle id="Oval-Copy-25" stroke="#5B439B" stroke-width="5" cx="313.362496" cy="573.671635" r="25"></circle>
      <circle id="Oval-Copy-25" fill="#63E3E8" cx="849.143525" cy="270.931248" r="12.5687521"></circle>
    </g>
    <g id="Group-2-Copy-2" opacity="0.996396019" transform="translate(1150.273500, 1064.548400)"></g>
  </g>
</svg>

<svg class="background" *ngIf="theme === 'dark-theme'" width="2048px" height="1536px" viewBox="0 0 2048 1536"
     version="1.1" xmlns="http://www.w3.org/2000/svg">
  <title>Mankido Glücksrad Alpha v1 Copy 29</title>
  <g id="Mankido-Glücksrad-Alpha-v1-Copy-29" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect id="Rectangle" fill="#202020" x="0" y="0" width="2048" height="1536"></rect>
    <path d="M815.532553,135.9274 L851.253039,135.9274 C852.909894,135.9274 854.253039,137.270546 854.253039,138.9274 C854.253039,139.351837 854.162978,139.771442 853.988804,140.158494 L836.128561,179.847924 C835.448646,181.358845 833.672623,182.032509 832.161702,181.352594 C831.493139,181.051741 830.957885,180.516486 830.657032,179.847924 L812.796789,140.158494 C812.116874,138.647573 812.790538,136.87155 814.301459,136.191636 C814.688512,136.017462 815.108117,135.9274 815.532553,135.9274 Z" id="Path-11-Copy-8" fill="#FFD700" transform="translate(833.392796, 160.927400) scale(1, -1) rotate(-119.000000) translate(-833.392796, -160.927400) "></path>
    <path d="M414.314595,1246.75377 L472.685405,1246.75377 C474.342259,1246.75377 475.685405,1248.09692 475.685405,1249.75377 C475.685405,1250.42598 475.459648,1251.0787 475.044342,1251.60726 L445.858936,1288.75155 C444.835283,1290.05436 442.949316,1290.28065 441.646512,1289.257 C441.458487,1289.10926 441.2888,1288.93958 441.141064,1288.75155 L411.955658,1251.60726 C410.932005,1250.30446 411.158302,1248.41849 412.461106,1247.39484 C412.989667,1246.97953 413.642393,1246.75377 414.314595,1246.75377 Z" id="Path-11-Copy-8" fill="#D07FA6" transform="translate(443.500000, 1269.253773) rotate(-108.000000) translate(-443.500000, -1269.253773) "></path>
    <path d="M1807.77021,928.24865 L1840.42527,928.24865 C1842.08212,928.24865 1843.42527,929.591796 1843.42527,931.24865 C1843.42527,931.920852 1843.19951,932.573578 1842.7842,933.102139 L1826.45667,953.8822 C1825.43302,955.185004 1823.54705,955.411301 1822.24425,954.387648 C1822.05622,954.239911 1821.88654,954.070224 1821.7388,953.8822 L1805.41127,933.102139 C1804.38762,931.799334 1804.61391,929.913367 1805.91672,928.889714 C1806.44528,928.474408 1807.09801,928.24865 1807.77021,928.24865 Z" id="Path-11-Copy-8" fill="#63E3E8" transform="translate(1824.097737, 942.566535) rotate(-48.000000) translate(-1824.097737, -942.566535) "></path>
    <path d="M1825.0806,282.765242 L1857.73566,282.765242 C1859.39252,282.765242 1860.73566,284.108387 1860.73566,285.765242 C1860.73566,286.437444 1860.5099,287.090169 1860.0946,287.61873 L1843.76707,308.398791 C1842.74342,309.701596 1840.85745,309.927893 1839.55464,308.904239 C1839.36662,308.756503 1839.19693,308.586816 1839.0492,308.398791 L1822.72167,287.61873 C1821.69801,286.315925 1821.92431,284.429959 1823.22711,283.406305 C1823.75568,282.990999 1824.4084,282.765242 1825.0806,282.765242 Z" id="Path-11-Copy-8" fill="#34C39C"></path>
    <path d="M336.645675,378.1741 L369.300733,378.1741 C370.957588,378.1741 372.300733,379.517246 372.300733,381.1741 C372.300733,381.846303 372.074976,382.499028 371.65967,383.027589 L355.33214,403.80765 C354.308487,405.110455 352.42252,405.336751 351.119716,404.313098 C350.931691,404.165361 350.762004,403.995675 350.614268,403.80765 L334.286738,383.027589 C333.263085,381.724784 333.489382,379.838817 334.792186,378.815164 C335.320747,378.399858 335.973473,378.1741 336.645675,378.1741 Z" id="Path-11-Copy-8" fill="#5B439B" transform="translate(352.973204, 392.491985) rotate(56.000000) translate(-352.973204, -392.491985) "></path>
    <path d="M1426.38425,1434.37999 L1459.03931,1434.37999 C1460.69616,1434.37999 1462.03931,1435.72314 1462.03931,1437.37999 C1462.03931,1438.05219 1461.81355,1438.70492 1461.39824,1439.23348 L1445.07071,1460.01354 C1444.04706,1461.31635 1442.16109,1461.54264 1440.85829,1460.51899 C1440.67026,1460.37125 1440.50058,1460.20157 1440.35284,1460.01354 L1424.02531,1439.23348 C1423.00166,1437.93068 1423.22795,1436.04471 1424.53076,1435.02106 C1425.05932,1434.60575 1425.71204,1434.37999 1426.38425,1434.37999 Z" id="Path-11-Copy-9" fill="#5B439B" transform="translate(1442.711776, 1448.697877) rotate(56.000000) translate(-1442.711776, -1448.697877) "></path>
    <path d="M1622.83995,227.31697 C1622.83995,227.31697 1670.7616,204.81697 1725.36281,227.31697" id="Path-12-Copy-4" stroke="#FFD700" stroke-width="15" transform="translate(1674.101379, 222.316970) rotate(-53.000000) translate(-1674.101379, -222.316970) "></path>
    <path d="M81,555.9948 C81,555.9948 128.921653,533.4948 183.522857,555.9948" id="Path-12-Copy-4" stroke="#B766FF" stroke-width="15" transform="translate(132.261428, 550.994800) rotate(41.000000) translate(-132.261428, -550.994800) "></path>
    <path d="M1665.25016,1314.83399 C1665.25016,1314.83399 1713.17181,1292.33399 1767.77301,1314.83399" id="Path-12-Copy-8" stroke="#34C39C" stroke-width="15" transform="translate(1716.511586, 1309.833994) rotate(-29.000000) translate(-1716.511586, -1309.833994) "></path>
    <circle id="Oval-Copy-25" stroke="#FF86DF" stroke-width="5" cx="1938" cy="663.265242" r="25"></circle>
    <circle id="Oval-Copy-25" stroke="#B766FF" stroke-width="5" cx="1370.7735" cy="153.368697" r="25"></circle>
    <circle id="Oval-Copy-25" stroke="#5B439B" stroke-width="5" cx="727.060204" cy="1499.48227" r="15"></circle>
    <circle id="Oval-Copy-26" stroke="#B766FF" stroke-width="15" cx="444.175044" cy="51.2652416" r="33"></circle>
    <circle id="Oval-Copy-25" stroke="#00C69A" stroke-width="7" cx="190.729215" cy="282.765242" r="13"></circle>
    <circle id="Oval-Copy-25" fill="#5B439B" cx="1938" cy="1408.83399" r="12.5687521"></circle>
    <circle id="Oval-Copy-25" fill="#34C39C" cx="219.797967" cy="1188.33399" r="12.5687521"></circle>
    <g id="Group-Copy-3" transform="translate(1818.908132, 442.491985) rotate(-315.000000) translate(-1818.908132, -442.491985) translate(1804.408132, 427.991985)" fill="#FFD700">
      <circle id="Oval-Copy-10" cx="3.47136963" cy="3.47136963" r="3.47136963"></circle>
      <circle id="Oval-Copy-13" cx="3.47136963" cy="14.4457995" r="3.47136963"></circle>
      <circle id="Oval-Copy-11" cx="14.272488" cy="3.47136963" r="3.47136963"></circle>
      <circle id="Oval-Copy-14" cx="14.272488" cy="14.4457995" r="3.47136963"></circle>
      <circle id="Oval-Copy-12" cx="25.0439165" cy="3.47136963" r="3.47136963"></circle>
      <circle id="Oval-Copy-15" cx="25.0439165" cy="14.4457995" r="3.47136963"></circle>
      <circle id="Oval-Copy-18" cx="3.47136963" cy="25.4934186" r="3.47136963"></circle>
      <circle id="Oval-Copy-17" cx="14.272488" cy="25.4934186" r="3.47136963"></circle>
      <circle id="Oval-Copy-16" cx="25.0439165" cy="25.4934186" r="3.47136963"></circle>
    </g>
    <g id="Group-Copy-3" transform="translate(589.500000, 233.265242)" fill="#FFD700">
      <circle id="Oval-Copy-10" cx="3.95017923" cy="3.95017923" r="3.95017923"></circle>
      <circle id="Oval-Copy-13" cx="3.95017923" cy="16.4383236" r="3.95017923"></circle>
      <circle id="Oval-Copy-11" cx="16.241107" cy="3.95017923" r="3.95017923"></circle>
      <circle id="Oval-Copy-14" cx="16.241107" cy="16.4383236" r="3.95017923"></circle>
      <circle id="Oval-Copy-12" cx="28.4982498" cy="3.95017923" r="3.95017923"></circle>
      <circle id="Oval-Copy-15" cx="28.4982498" cy="16.4383236" r="3.95017923"></circle>
      <circle id="Oval-Copy-18" cx="3.95017923" cy="29.0097521" r="3.95017923"></circle>
      <circle id="Oval-Copy-17" cx="16.241107" cy="29.0097521" r="3.95017923"></circle>
      <circle id="Oval-Copy-16" cx="28.4982498" cy="29.0097521" r="3.95017923"></circle>
    </g>
    <g id="Group-Copy-3" transform="translate(1650.766855, 1522.816791) rotate(-45.000000) translate(-1650.766855, -1522.816791) translate(1634.266855, 1506.316791)" fill="#FFD700">
      <circle id="Oval-Copy-10" cx="3.95017923" cy="3.95017923" r="3.95017923"></circle>
      <circle id="Oval-Copy-13" cx="3.95017923" cy="16.4383236" r="3.95017923"></circle>
      <circle id="Oval-Copy-11" cx="16.241107" cy="3.95017923" r="3.95017923"></circle>
      <circle id="Oval-Copy-14" cx="16.241107" cy="16.4383236" r="3.95017923"></circle>
      <circle id="Oval-Copy-12" cx="28.4982498" cy="3.95017923" r="3.95017923"></circle>
      <circle id="Oval-Copy-15" cx="28.4982498" cy="16.4383236" r="3.95017923"></circle>
      <circle id="Oval-Copy-18" cx="3.95017923" cy="29.0097521" r="3.95017923"></circle>
      <circle id="Oval-Copy-17" cx="16.241107" cy="29.0097521" r="3.95017923"></circle>
      <circle id="Oval-Copy-16" cx="28.4982498" cy="29.0097521" r="3.95017923"></circle>
    </g>
    <circle id="Oval-Copy-25" fill="#FF59A9" cx="244.935471" cy="1471.91351" r="12.5687521"></circle>
    <polyline id="Path-11-Copy-15" stroke="#34C39C" stroke-width="15" points="65.7292145 968.867829 90.7292145 988.867829 115.729215 968.867829 140.729215 988.867829 165.729215 968.867829 190.729215 988.867829"></polyline>
    <polyline id="Path-11-Copy-15" stroke="#FFD700" stroke-width="15" transform="translate(1925.431248, 1104.765242) rotate(-30.000000) translate(-1925.431248, -1104.765242) " points="1862.93125 1094.76524 1887.93125 1114.76524 1912.93125 1094.76524 1937.93125 1114.76524 1962.93125 1094.76524 1987.93125 1114.76524"></polyline>
    <polyline id="Path-11-Copy-15" stroke="#FF86DF" stroke-width="5" transform="translate(1036.876894, 51.265242) rotate(-315.000000) translate(-1036.876894, -51.265242) " points="974.376894 41.2652416 999.376894 61.2652416 1024.37689 41.2652416 1049.37689 61.2652416 1074.37689 41.2652416 1099.37689 61.2652416"></polyline>
    <rect id="Rectangle-Copy-40" fill="#5B439B" transform="translate(132.261428, 88.530483) rotate(39.000000) translate(-132.261428, -88.530483) " x="118.261428" y="74.5304833" width="28" height="28" rx="5"></rect>
    <rect id="Rectangle-Copy-40" fill="#BAD8FF" transform="translate(98.570899, 1396.265242) rotate(72.000000) translate(-98.570899, -1396.265242) " x="84.5708994" y="1382.26524" width="28" height="28" rx="5"></rect>
    <path d="M419.858204,1504.48227 C419.858204,1504.48227 467.779858,1481.98227 522.381061,1504.48227" id="Path-12-Copy-8" stroke="#BAD8FF" stroke-width="15" transform="translate(471.119633, 1499.482267) rotate(-52.000000) translate(-471.119633, -1499.482267) "></path>
    <rect id="Rectangle-Copy-40" fill="#FF59A9" x="1936.56875" y="164.726584" width="28" height="28" rx="5"></rect>
    <circle id="Oval-Copy-25" fill="#34C39C" cx="1128.56875" cy="1497.05102" r="12.5687521"></circle>
  </g>
</svg>
