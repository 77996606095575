import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {PosExperienceRelation} from '../../pos-experiences/pos_experience';

@Component({
  selector: 'app-wheel-of-luck-overlay',
  templateUrl: './wheel-of-luck-overlay.component.html',
  styleUrls: ['./wheel-of-luck-overlay.component.scss']
})
export class WheelOfLuckOverlayComponent {

  @Input()
  theme: 'dark-theme' | 'light-theme';

  @Input()
  winning: PosExperienceRelation;

  @Input()
  nextSubject: EventEmitter<void>;


  constructor() {
  }
}
