<app-head-content>
  <div class="title-with-button">
    <h1>Kampagnen</h1>
    <a class="btn btn-primary" [routerLink]="'/campaigns/new'">Neue Kampagne</a>
  </div>
</app-head-content>

<ng-container *ngIf="drafts?.length > 0">
  <app-head-content>
    <div class="title-with-button">
      <h2>Entwürfe</h2>
    </div>
  </app-head-content>

  <div class="row d-md-none pb-1">
    <div class="col-12 campaign-row" *ngFor="let draft of drafts">
      <h6 class="campaign-title">
        <i class="fa fa-pencil status-icon"></i>
        <em>{{ draft.human_title || ' Unbenannter Entwurf ' }}</em>
      </h6>
      <span class="campaign-subtitle">{{ getCampaignGoalTitle(draft) }}</span>

      <div class="pull-right">
        <button class="btn btn-sm btn-secondary mr-1" [disabled]="loadingDraftId" (click)="navigateToDraft(draft)">
          <i class="fa fa-pencil"></i>
        </button>
        <button class="btn btn-sm btn-danger" [disabled]="loadingDraftId" (click)="destroy(draft)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="table-responsive d-none d-md-block pb-1">
    <table class="table campaign-table">
      <tr *ngFor="let draft of drafts">
        <td style="width: 1%;">
          <i class="fa fa-pencil status-icon"></i>
        </td>
        <td style="min-width: 300px">
          <h6><em>{{ draft.human_title || ' Unbenannter Entwurf ' }}</em></h6>
          <span>{{ getCampaignGoalTitle(draft) }}</span>
        </td>
        <td class="button-cell">
          <div class="btn-group pull-right d-none d-md-flex">
            <button [disabled]="loadingDraftId" (click)="navigateToDraft(draft)"
                    class="btn btn-secondary">{{ draft.id === loadingDraftId ? 'Wird geladen ...' : 'Bearbeiten' }}
            </button>
            <div ngbDropdown class="btn-group" role="group" placement="bottom-right">
              <button [disabled]="loadingDraftId" class="btn btn-secondary dropdown-toggle" type="button"
                      id="dropdownMenuButton" ngbDropdownToggle>
              </button>
              <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownMenuButton"
                   style="min-width: 0;">
                <a class="dropdown-item" (click)="destroy(draft)">Löschen</a>
              </div>
            </div>
          </div>
          <div class="d-block d-md-none d-flex align-items-center">
            <button class="btn btn-secondary mr-1" [disabled]="loadingDraftId" (click)="navigateToDraft(draft)">
              <i class="fa fa-pencil" style="font-size: 1rem"></i>
            </button>
            <button class="btn btn-secondary" [disabled]="loadingDraftId" (click)="destroy(draft)">
              <i class="fa fa-trash" style="font-size: 1rem"></i>
            </button>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <app-head-content>
    <div class="title-with-button">
      <h2>Laufende und abgeschlossene</h2>

      <ngb-pagination aria-label="Default pagination" class="d-flex justify-content-center" size="sm" style="margin-bottom: -1rem;"
                      *ngIf="campaigns && total_sent > 10"
                      [collectionSize]="total_sent" [(page)]="page" [maxSize]="3"
                      (pageChange)="refreshCampaignList()"></ngb-pagination>
    </div>
  </app-head-content>
</ng-container>

<div class="row d-md-none">
  <div class="col-12 campaign-row" *ngFor="let campaign of campaigns">
    <h6 class="campaign-title">
      <img src="../../../../assets/img/blue_checkmark.svg" class="checkmark-icon" *ngIf="campaign.status !== 'pristine'">
      <i class="fa fa-paper-plane status-icon" *ngIf="campaign.status === 'pristine'"></i>

      {{ campaign.human_title || ' - ' }}
    </h6>
    <span class="campaign-statistics">
      <strong>{{ campaign?.uniqueRecipients || ' - ' }}</strong> Empfänger
    </span>
    <span class="campaign-statistics" *ngIf="campaign.total_obtained_reviews === null">
      <strong>{{ campaign.revenues.total }}€</strong> Umsatz
    </span>
    <span class="campaign-statistics" *ngIf="campaign.total_obtained_reviews !== null">
      <strong>{{ campaign.total_obtained_reviews }}</strong> Bewertungen
    </span>

    <br>

    <span class="campaign-subtitle">
      <ng-container *ngIf="campaign.delivery_period !== 'scheduledsend' && campaign.status === 'pristine'">
        Wird gerade versendet...
      </ng-container>
      <ng-container *ngIf="campaign.delivery_period !== 'scheduledsend' && campaign.status !== 'pristine'">
        Gesendet am {{ campaign.sent_at | date:'fullDate' }} um {{ campaign.sent_at | date:'HH:mm' }} Uhr
      </ng-container>
      <ng-container *ngIf="campaign.delivery_period === 'scheduledsend'">
        <ng-container *ngIf="campaign.status !== 'pristine'">
          Gesendet am {{ campaign.scheduledsend_at | date: 'fullDate' }} um {{ campaign.scheduledsend_at | date: 'HH:mm' }}
          Uhr
        </ng-container>
        <ng-container *ngIf="campaign.status === 'pristine'">
        Wird am {{ campaign.scheduledsend_at | date: 'fullDate' }}
          um {{ campaign.scheduledsend_at | date: 'HH:mm' }}
          Uhr gesendet
        </ng-container>
      </ng-container>
    </span>

    <div class="btn-group pull-right">
      <button [disabled]="creatingDraftId" [routerLink]="['/campaigns/'+campaign.id+'/stats']"
              class="btn btn-sm btn-secondary">{{ creatingDraftId === campaign.id ? 'Erstelle Vorlage ...' : 'Analyse' }}
      </button>
      <div ngbDropdown class="btn-group" role="group" placement="bottom-right">
        <button [disabled]="creatingDraftId" class="btn btn-sm btn-secondary dropdown-toggle" type="button"
                id="dropdownMenuButton3" ngbDropdownToggle>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownMenuButton3"
             style="min-width: 0;">
          <a class="dropdown-item" [routerLink]="['/campaigns/'+campaign.id]">Anzeigen</a>
          <a class="dropdown-item" (click)="useAsTemplate(campaign)">Als Vorlage verwenden</a>
          <a class="dropdown-item" (click)="destroy(campaign)"
             *ngIf="campaign.delivery_period === 'scheduledsend' && campaign.status === 'pristine'">Löschen</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive d-none d-md-block">
  <table class="table campaign-table">
    <tr *ngFor="let campaign of campaigns">
      <td class="status-icon-cell">
        <img src="../../../../assets/img/blue_checkmark.svg" class="checkmark-icon" *ngIf="campaign.status !== 'pristine'">
        <i class="fa fa-paper-plane status-icon" *ngIf="campaign.status === 'pristine'"></i>
      </td>
      <td style="min-width: 300px">
        <h6>
          {{ campaign.human_title || ' - ' }}
        </h6>
        <span *ngIf="campaign.delivery_period !== 'scheduledsend' && campaign.status === 'pristine'">
        Wird gerade versendet...
      </span>
        <span *ngIf="campaign.delivery_period !== 'scheduledsend' && campaign.status !== 'pristine'">
        Gesendet am {{ campaign.sent_at | date:'fullDate' }} um {{ campaign.sent_at | date:'HH:mm' }} Uhr
      </span>
        <ng-container *ngIf="campaign.delivery_period === 'scheduledsend'">
        <span *ngIf="campaign.status !== 'pristine'">
          Gesendet am {{ campaign.scheduledsend_at | date: 'fullDate' }} um {{ campaign.scheduledsend_at | date: 'HH:mm' }}
          Uhr
        </span>
          <span *ngIf="campaign.status === 'pristine'">
        Wird am {{ campaign.scheduledsend_at | date: 'fullDate' }}
            um {{ campaign.scheduledsend_at | date: 'HH:mm' }}
            Uhr gesendet
      </span>

        </ng-container>
      </td>
      <td class="text-center" style="min-width: 100px">
        <h6>
          {{ campaign?.uniqueRecipients || ' - ' }}
        </h6>
        <span>Empfänger</span>
      </td>
      <td class="text-center" style="min-width: 100px">
        <ng-container *ngIf="campaign.total_obtained_reviews === null">
          <h6>{{ campaign.revenues.total }}€</h6>
          <span>Umsatz</span>
        </ng-container>
        <ng-container *ngIf="campaign.total_obtained_reviews !== null">
          <h6>{{ campaign.total_obtained_reviews }}</h6>
          <span>Bewertungen</span>
        </ng-container>
      </td>
      <td class="button-cell">
        <div class="btn-group pull-right">
          <button [disabled]="creatingDraftId" [routerLink]="['/campaigns/'+campaign.id+'/stats']"
                  class="btn btn-secondary">{{ creatingDraftId === campaign.id ? 'Erstelle Vorlage ...' : 'Analyse' }}
          </button>
          <div ngbDropdown class="btn-group" role="group" placement="bottom-right">
            <button [disabled]="creatingDraftId" class="btn btn-secondary dropdown-toggle" type="button"
                    id="dropdownMenuButton2" ngbDropdownToggle>
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownMenuButton2"
                 style="min-width: 0;">
              <a class="dropdown-item" [routerLink]="['/campaigns/'+campaign.id]">Anzeigen</a>
              <a class="dropdown-item" (click)="useAsTemplate(campaign)">Als Vorlage verwenden</a>
              <a class="dropdown-item" (click)="destroy(campaign)"
                 *ngIf="campaign.delivery_period === 'scheduledsend' && campaign.status === 'pristine'">Löschen</a>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
<div class="pb-5">
  <ngb-pagination aria-label="Default pagination" class="d-flex justify-content-end" size="sm"
                  *ngIf="campaigns && total_sent > 10"
                  [collectionSize]="total_sent" [(page)]="page" [maxSize]="5"
                  (pageChange)="refreshCampaignList()"></ngb-pagination>
</div>

<div *ngIf="campaigns?.length === 0" class="text-center row">
  <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
    <i class="fa fa-paper-plane text-primary" style="margin: 2rem auto; font-size: 3rem;"></i>
    <h3>Noch keine Kampagne vorhanden</h3>
    <p>Erstelle ein Kampagne, indem du ein Angebot oder eine Nachricht erstellst und an deine Kunden versendest. Wir
      liefern dir anschließend perfekte Auswertungen.</p>
    <p class="mt-2">
      <a class="btn btn-secondary" [routerLink]="'/campaigns/new'">Neue Kampagne</a>
    </p>
  </div>
</div>

<div *ngIf="!campaigns" class="text-center row">
  <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
    <i class="fa fa-paper-plane text-muted" style="margin: 2rem auto 1rem; font-size: 2rem;"></i>
    <h5 class="text-muted">Daten werden geladen...</h5>
  </div>
</div>
