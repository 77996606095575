import {Component} from '@angular/core';

@Component({
  selector: 'app-cards',
  template: '<ng-content></ng-content>',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent {

}
