import {Client} from './client';

export class Merchant {
  id: number;
  username: string;
  is_disabled = false;
  is_single_context = true;
  client_id: number;
  client: Client;
  available_contexts: any[] = [];
  default_context: any;

  constructor(values: Object = {}) {
    Object.assign(this as any, values);
  }
}
