import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdventCalendar} from '../../pos_experience';

@Component({
  selector: 'app-advent-calendar',
  templateUrl: './advent-calendar.component.html',
  styleUrls: ['./advent-calendar.component.sass']
})
export class AdventCalendarComponent {

  @Input() posExperience: AdventCalendar;
  @Output() save = new EventEmitter();

  constructor() {
  }

  dayFrom(number: number) {
    return new Date(new Date().getFullYear(), 11, number);
  }

  statusChanged(active: boolean) {
    this.posExperience.state = (active ? 'active' : 'inactive');
  }

  isActive() {
    return this.posExperience.state === 'active';
  }
}
