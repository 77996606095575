import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent {

  date: Date;
  time: NgbTimeStruct = {hour: 10, minute: 0, second: 0};
  datetimeValue: Date;

  @Input()
  seconds: boolean;

  @Input()
  minuteStep = 1;

  @Input()
  onlyTime = false;

  @Input()
  valid = true;

  @Input()
  timeDisabled = false;

  @Input()
  dateDisabled = false;

  @Output()
  change: EventEmitter<Date> = new EventEmitter<Date>();
  @Output()
  datetimeChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() {
  }

  get datetime() {
    return this.datetimeValue;
  }

  @Input()
  set datetime(datetime: Date) {
    if (datetime == null) {
      return;
    }
    this.datetimeValue = datetime;
    this.date = datetime;

    this.time = {hour: this.datetimeValue.getHours(), minute: this.datetimeValue.getMinutes(), second: 0};
    if (this.seconds) {
      this.time.second = this.datetimeValue.getSeconds();
    }
    this.checkTime();
  }

  timeChanged(ev) {
    this.time = ev;
    this.checkTime();
    this.onChange();
  }

  checkTime() {
    let minute = Math.round(this.time.minute / this.minuteStep) * this.minuteStep;
    let hour = this.time.hour + Math.floor(minute / 60);
    if (minute >= 60) {
      minute = minute % 60;
    }
    if (hour >= 24) {
      hour = 0;
    }
    const correctedTime = this.time.hour !== hour || this.time.minute !== minute;
    this.time = {hour: hour, minute: minute, second: 0};
    if (correctedTime) {
      this.onChange();
    }
  }

  refreshModel() {
    if (this.date == null) {
      return;
    }
    this.date.setHours(this.time.hour);
    this.date.setMinutes(this.time.minute);
    this.date.setSeconds(this.time.second);

    const datetimeValue = new Date(this.date);
    this.datetimeValue = datetimeValue;
  }

  onChange() {
    this.refreshModel();
    this.datetimeChange.emit(this.datetime);
    this.change.emit(this.datetime);
  }
}
