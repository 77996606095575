import {Component, Input} from '@angular/core';
import {PrizeType} from '../models/prize';
import {SpecialPointsEvent} from '../../special-points-events/special-points-event';
import {Reward} from '../../loyalty/rewards/reward';

@Component({
  selector: 'app-prize-description',
  templateUrl: 'prize-description.html',
  styleUrls: ['./prize-description.scss']
})
export class PrizeDescriptionComponent {

  @Input()
  type: PrizeType;


  @Input()
  object: SpecialPointsEvent | Reward;

  text: string;

  constructor() {
  }

}
