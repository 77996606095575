import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';
import {AppComponent} from './app.component';
import {AuthGuard, AuthService} from './auth';
import {AppRoutingModule} from './app.routing';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {ModalComponent} from './shared/modals/modal.component';
import {DealListComponent} from './deals/deal-list/deal-list.component';
import {LogoutComponent} from './auth/logout';
import {MissingComponent} from './missing/missing.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {WallpaperModule} from './loyalty/appearance/wallpaper/wallpaper.module';
import {UiSwitchModule} from 'ngx-ui-switch';
import {DndModule} from 'ngx-drag-drop';
import {WheelOfLuckModule} from './wheel-of-luck/wheel-of-luck.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './shared/http/auth.interceptor';
import {CardsModule} from './shared/cards/cards.module';
import {HeadcontentModule} from './shared/head-content/headcontent.module';
import {ImageContainerModule} from './assets/image-container/image-container.module';
import {IpadPreviewContainerModule} from './ipad-preview-container/ipad-preview-container.module';
import {RoundPipeModule} from './shared/pipes/round-pipe/round-pipe.module';
import {SpecialPointsEventPreviewModule} from './special-points-events/special-points-event-preview/special-points-event-preview.module';
import {SpecialPointsEventEditorModule} from './special-points-events/special-points-event-editor/special-points-event-editor.module';
import {DateTimePickerModule} from './shared/date-time-picker/date-time-picker.module';
import {ContractsModule} from './contracts/contracts.module';
import {SepaMandatesModule} from './sepa-mandates/sepa-mandates.module';
import {DealFormModule} from './deals/deal-form/deal-form.module';
import {UploaderPlusModule} from './assets/uploader-plus/uploader-plus.module';
import {DatepickerModule} from './shared/datepicker/datepicker.module';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {HelpCardModule} from './shared/help-card/help-card.module';
import {ObtainReviewModule} from './obtain-review/obtain-review.module';
import {AddonsModule} from './addons/addons.module';
import {CampaignsModule} from './campaigns/campaigns.module';
import {TrixModule} from './trix/trix.module';
import {DragulaModule} from 'ng2-dragula';
import {PosExperiencesModule} from './pos-experiences/pos-experiences.module';
import * as Sentry from '@sentry/angular';
import {Router} from '@angular/router';
import {ReleaseMonitorModule} from './release-monitor/release-monitor.module';
import {ToastrModule} from 'ngx-toastr';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DealListComponent,
    LogoutComponent,
    MissingComponent,
    UnauthorizedComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    AppRoutingModule,
    NgxPaginationModule,
    FontAwesomeModule,
    NgbDropdownModule,
    NgxSkeletonLoaderModule,
    WallpaperModule,
    UiSwitchModule,
    DndModule,
    WheelOfLuckModule,
    HelpCardModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      countDuplicates: true,
      newestOnTop: false
    }),

    // TODO remove once lazy loading is implemented everywhere
    CardsModule,
    HeadcontentModule,
    ImageContainerModule,
    IpadPreviewContainerModule,
    RoundPipeModule,
    SpecialPointsEventPreviewModule,
    SpecialPointsEventEditorModule,
    DateTimePickerModule,
    ContractsModule,
    SepaMandatesModule,
    DealFormModule,
    UploaderPlusModule,
    DatepickerModule,
    NgxSliderModule,
    ObtainReviewModule,
    AddonsModule,
    CampaignsModule,
    TrixModule,
    DragulaModule.forRoot(),
    PosExperiencesModule,
    ReleaseMonitorModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-de'},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({showDialog: false})
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    AuthGuard
  ],
  // entryComponents: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
