import {Wallpaper} from './wallpaper';

export interface SliderSettings {
  delay: number;
  effect: string;
}

export class Partner {
  id: number;
  name: string;
  contact: string;
  address: string;
  zip: string;
  city: string;
  email: string;
  phone: string;
  iban: string;
  bic: string;
  logo: string;
  cover: string;
  wallpaper: string;
  wallpapers: Wallpaper[];
  wallpapers_attributes: any;
  wallpaper_settings: SliderSettings = { delay: 5, effect: 'fade'};
  created_at: string;
  tabakerzg_restricted: boolean;
  has_third_party_businesses: boolean;

  constructor(v: Partial<Partner> = {}) {
    Object.assign(this as any, v);
    this.wallpapers = v.wallpapers ?? [];

    if (!this.wallpaper_settings || !this.wallpaper_settings.delay) {
      this.wallpaper_settings = { delay: 5, effect: 'fade'};
    }
  }

  wallpaperUrls() {
    if (this.wallpapers) {
      return this.wallpapers.map(el => {
        if (!el._destroy) { return el.url; } });
    }
  }
}
