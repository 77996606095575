<app-head-content *ngIf="events?.length > 0">
  <div class="title-with-button">
    <h1>Spezial-Punkte Events</h1>
    <button type="button" class="btn btn-primary" (click)="createNewEvent()">Neues Event</button>
  </div>
</app-head-content>

<app-cards class="row">
  <div class="col-12 col-md-4 card card-flex text-center" style="width: 100%; height: 100%;" *ngFor="let event of events">
    <div class="card-block">
      <app-special-points-event-preview [specialPointsEvent]="event"></app-special-points-event-preview>
      <div class="d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="d-flex align-items-center">
          <ui-switch [(ngModel)]="event.active" size="medium"
                     (change)="eventStatusChanged(event, $event)"></ui-switch>
          <span class="text-muted">{{event.active ? 'aktiv' : 'deaktiviert'}}</span>
        </div>
        <div class="btn-group pull-right mt-1 mt-md-0">
          <button [routerLink]="['/special_points_events', event.id]"
                  class="btn" [ngClass]="event.active ? 'btn-primary' : 'btn-secondary'">Verwalten
          </button>
          <button (click)="destroyEvent(event)" class="btn btn-secondary d-block d-md-none rounded-right">
            Löschen
          </button>
          <div ngbDropdown class="btn-group d-none d-md-block" role="group">
            <button class="btn dropdown-toggle" [ngClass]="event.active ? 'btn-primary' : 'btn-secondary'"
                    type="button"
                    id="dropdownMenuButton" ngbDropdownToggle>
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownMenuButton"
                 style="min-width: 0">
              <a class="dropdown-item" (click)="destroyEvent(event)">Löschen</a>
            </div>
          </div>
        </div>
        <!--        <a [routerLink]="['/special_points_events', event.id]" class="btn">Verwalten</a>-->
      </div>
    </div>
  </div>

</app-cards>

<div *ngIf="events?.length === 0" class="text-center row">
  <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
    <img src="assets/img/multiplicator.svg" style="width: 120px; margin: 30px;" alt=""/>
    <h3>Noch keine Spezial-Punkte Events vorhanden</h3>
    <p>Mit Spezial-Punkte Events kannst Du, ähnlich wie bei einer Happy Hour, Extra-Punkte und Doppel-Punkte in vorab
      festgelegten Zeiträumen vergeben. So kannst du deinen Kundenstrom besser lenken und beispielsweise deinen Kunden
      einen stärkeren Anreiz geben, auch außerhalb der Stoßzeiten bei Dir einzukaufen!</p>
    <p class="mt-2">
      <button type="button" class="btn btn-success" (click)="createNewEvent()">Erstes Event anlegen</button>
    </p>
  </div>
</div>
