import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RecurringEvent, RecurringEventRule} from '../recurring-event';

@Component({
  selector: 'app-recurring-event-picker',
  templateUrl: './recurring-event-picker.component.html',
  styleUrls: ['./recurring-event-picker.component.scss']
})
export class RecurringEventPickerComponent implements OnInit {

  @Input()
  recurringEvents: RecurringEvent[];

  @Input()
  rules: RecurringEventRule[] = [];

  @Input()
  title = 'Wähle den Rhytmus des Events:';

  @Output()
  recurringEventsChange: EventEmitter<RecurringEvent[]> = new EventEmitter<RecurringEvent[]>();

  @Output()
  change: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onChange: EventEmitter<void> = new EventEmitter<void>();


  rule: RecurringEventRule;

  datetime = {
    start: new Date(),
    end: new Date(new Date().getTime() + 60 * 60 * 1000)
  };

  time = {
    start: new Date(),
    end: new Date(new Date().getTime() + 60 * 60 * 1000)
  };

  weekDays = [false, false, false, false, false, false, false]; // from So to Sa, according to the week day index of JS

  day: number = new Date().getDate();
  month: number = new Date().getMonth() + 1;

  constructor() {
  }

  ngOnInit(): void {
    this.parseRecurringEvents();
  }

  parseRecurringEvents() {
    if (!this.recurringEvents || this.recurringEvents.length === 0) {
      this.rule = this.rules[0];
    } else {
      this.rule = this.recurringEvents[0].rule;
    }
    switch (this.recurringEvents[0].rule) {
      case 'permanent':
        this.datetime.start = new Date();
        break;

      case 'singular':
      case 'daily':
        this.datetime.start = this.recurringEvents[0].starts_at;
        this.datetime.end = this.recurringEvents[0].ends_at;
        break;

      case 'weekly':
        this.datetime.start = this.recurringEvents[0].starts_at;
        this.datetime.end = this.recurringEvents[0].ends_at;
        for (const re of this.recurringEvents) {
          this.weekDays[re.starts_at.getDay()] = true;
        }
        break;

      case 'monthly':
      case 'yearly':
        this.day = this.recurringEvents[0].starts_at.getDate();
        this.month = this.recurringEvents[0].starts_at.getMonth() + 1;
        break;
    }

    if (!this.recurringEvents || this.recurringEvents.length === 0) {
      this.recurringEvents = [
        new RecurringEvent({
          rule: this.rules[0],
          starts_at: new Date(),
          ends_at: new Date(new Date().getTime() + 1000 * 60 * 60)
        })
      ];
    }
  }

  recreateRecurringEvents() {
    let newRecurringEvents = [];
    if (this.rule !== 'permanent' && this.datetime.end == null) {
      this.datetime.end = new Date(this.datetime.start.getTime() + 1000 * 60 * 60);
    }
    switch (this.rule) {
      case 'permanent':
        newRecurringEvents = this.createPermanentEvent();
        break;
      case 'singular':
        newRecurringEvents = this.createSingularEvent();
        break;
      case 'daily':
        newRecurringEvents = this.createDailyEvent();
        break;
      case 'weekly':
        newRecurringEvents = this.createWeeklyEvents();
        break;
      case 'monthly':
        this.checkDayAndMonth();
        newRecurringEvents = this.createMonthlyEvent();
        break;
      case 'yearly':
        this.checkDayAndMonth();
        newRecurringEvents = this.createYearlyEvent();
        break;
    }
    this.recurringEvents = this.deleteCurrentAndMergeWith(this.recurringEvents, newRecurringEvents);
    this.recurringEventsChange.emit(this.recurringEvents);
    this.change.emit();
  }

  ruleHasChanged() {
    console.log('ruleHasChanged()');
    this.recreateRecurringEvents();
  }

  weekDayChanged() {
    this.recreateRecurringEvents();
  }

  private createPermanentEvent(): RecurringEvent[] {
    return [
      new RecurringEvent({
        rule: 'permanent',
        starts_at: new Date(),
        ends_at: null
      })
    ];
  }

  private createSingularEvent(): RecurringEvent[] {
    return [
      new RecurringEvent({
        rule: 'singular',
        starts_at: this.datetime.start,
        ends_at: this.datetime.end
      })
    ];
  }

  private createDailyEvent(): RecurringEvent[] {
    return [
      new RecurringEvent({
        rule: 'daily',
        starts_at: this.datetime.start,
        ends_at: this.datetime.end
      })
    ];
  }

  private createWeeklyEvents(): RecurringEvent[] {
    const mondayStart = new Date(this.datetime.start.getTime() - 1000 * 60 * 60 * 24 * (this.datetime.start.getDay() - 1));
    const mondayEnd = new Date(this.datetime.end.getTime() - 1000 * 60 * 60 * 24 * (this.datetime.end.getDay() - 1));

    let recurringEvents: RecurringEvent[] = [];
    for (let i = 0; i < this.weekDays.length; i++) {
      if (this.weekDays[i]) {
        recurringEvents.push(new RecurringEvent({
          rule: 'weekly',
          starts_at: new Date(mondayStart.getTime() + 1000 * 60 * 60 * 24 * (i - 1)),
          ends_at: new Date(mondayEnd.getTime() + 1000 * 60 * 60 * 24 * (i - 1))
        }));
      }
    }
    // check for sunday, and add 7 days to timestamp
    if (this.weekDays[0]) {
      recurringEvents[0].starts_at = new Date(recurringEvents[0].starts_at.getTime() + 1000 * 60 * 60 * 24 * 7);
      recurringEvents[0].ends_at = new Date(recurringEvents[0].ends_at.getTime() + 1000 * 60 * 60 * 24 * 7);
      recurringEvents = recurringEvents.slice(1).concat(recurringEvents.slice(0, 1));
    }
    return recurringEvents;
  }

  private createMonthlyEvent(): RecurringEvent[] {
    return [
      new RecurringEvent({
        rule: 'monthly',
        starts_at: new Date(this.datetime.start.setDate(this.day)),
        ends_at: new Date(this.datetime.end.setDate(this.day))
      })
    ];
  }

  private createYearlyEvent(): RecurringEvent[] {
    const start = new Date(this.datetime.start.setDate(this.day));
    start.setMonth(this.month - 1);
    const end = new Date(this.datetime.end.setDate(this.day));
    end.setMonth(this.month - 1);
    return [
      new RecurringEvent({
        rule: 'yearly',
        starts_at: start,
        ends_at: end
      })
    ];
  }

  private checkDayAndMonth() {
    if (this.day > 31) {
      this.day = 31;
    }
    if (this.day < 1) {
      this.day = 1;
    }
    if (this.month > 12) {
      this.month = 12;
    }
    if (this.month < 1) {
      this.month = 1;
    }
  }

  private deleteCurrentAndMergeWith(oldRecurringEvents, newRecurringEvents: RecurringEvent[]) {
    // mark old elements with _destroy = ture
    oldRecurringEvents = oldRecurringEvents.map(re => {
      return new RecurringEvent({...re, _destroy: true});
    });

    const merged: RecurringEvent[] = [];
    for (let i = 0; i < Math.max(newRecurringEvents.length, oldRecurringEvents.length); i++) {
      if (i < newRecurringEvents.length) {
        if (i < oldRecurringEvents.length) { // old elements can be replaced
          merged.push(new RecurringEvent({...newRecurringEvents[i], id: oldRecurringEvents[i].id}));
        } else { // no old elements left
          merged.push(newRecurringEvents[i]);
        }
      } else { // more old elements than new ones
        merged.push(oldRecurringEvents[i]);
      }
    }
    return merged;
  }
}
