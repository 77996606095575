<div class="event-container" *ngIf="specialPointsEvent && specialPointsEvent.type === 'referral_points_event'">
  <app-referral-points-event-preview [specialPointsEvent]="specialPointsEvent"></app-referral-points-event-preview>
</div>

<div class="event-container" *ngIf="specialPointsEvent && specialPointsEvent.type !== 'referral_points_event'">
  <svg height="100%" width="100%" viewBox="0 0 1160 790">
    <svg width="1160px" height="790px" viewBox="0 0 1160 790" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M34.8,1.13686838e-13 L999.146667,1.13686838e-13 C1018.36618,1.10156271e-13 1033.94667,15.5804907 1033.94667,34.8 C1033.94667,119.079969 1033.94667,203.359937 1033.94667,287.639906 C1033.94667,287.639906 999.471155,292.473366 999.471155,324.993333 C999.471155,345.78616 1010.96299,358.502361 1033.94667,363.141937 L1033.94667,615.186667 C1033.94667,634.406176 1018.36618,649.986667 999.146667,649.986667 L34.8,649.986667 C15.5804907,649.986667 4.64206489e-13,634.406176 4.54747351e-13,615.186667 L4.54747351e-13,363.141937 L4.54747351e-13,363.141937 C22.7211191,357.202985 34.0816786,344.486784 34.0816786,324.993333 C34.0816786,305.499883 22.7211191,293.04874 4.54747351e-13,287.639906 L4.54747351e-13,34.8 C4.5239364e-13,15.5804907 15.5804907,1.31428259e-13 34.8,1.13686838e-13 Z"
          id="path-1"></path>
      </defs>
      <g id="card_bg" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(63.000000, 70.000000)">
          <g id="Rectangle">
            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
            <use [attr.fill]="specialPointsEvent.type === 'extra_points_event' ? '#04BB83' : '#00A3E0'" fill-rule="evenodd" xlink:href="#path-1"></use>
          </g>
          <line x1="69.6" y1="325.186667" x2="972.66" y2="325.186667" id="Line-6" stroke="black" stroke-width="4.25333333" opacity="0.13" stroke-linecap="square"
                stroke-dasharray="35.18666666666667"></line>
        </g>
      </g>
    </svg>

    <g transform="translate(811.586667, 143.853333)">
      <mask id="circle-mask">
        <circle cx="81.9733333" cy="81.9733333" r="81.9733333" stroke="black" stroke-width="0" fill="white"/>
      </mask>
      <image *ngIf="specialPointsEvent?.partner?.logo" [attr.xlink:href]="specialPointsEvent?.partner?.logo" x="0" y="0" width="163.9466666" height="163.9466666" mask="url(#circle-mask)"/>
    </g>

    <g transform="translate(170, 295)" *ngIf="specialPointsEvent?.type === 'points_multiplier_event'">
      <ng-container *ngIf="specialPointsEvent.multiplier >= 2">
        <text font-family="Open Sans" font-size="195" font-weight="600" fill="white">{{specialPointsEvent.multiplier}}</text>
        <text font-family="Open Sans" font-size="97" font-weight="600" fill="white" fill-opacity="0.8" [attr.x]="116 + (specialPointsEvent.multiplier === 10 ? 116 : 0)">FACH</text>
        <text font-family="Open Sans" font-size="97" font-weight="600" fill="white" fill-opacity="0.8" [attr.x]="116 + (specialPointsEvent.multiplier === 10 ? 116 : 0)">FACH</text>
        <text font-family="Open Sans" font-size="78" font-weight="400" fill="white" fill-opacity="0.6" [attr.x]="367 + (specialPointsEvent.multiplier === 10 ? 116 : 0)">Pkt.</text>
      </ng-container>

      <ng-container *ngIf="specialPointsEvent.multiplier < 2">
        <text font-family="Open Sans" font-size="195" font-weight="600" fill="white">+{{(specialPointsEvent.multiplier - 1) * 100 | round}}</text>
        <text font-family="Open Sans" font-size="97" font-weight="600" fill="white" fill-opacity="0.8" [attr.x]="338">%</text>
        <text font-family="Open Sans" font-size="78" font-weight="400" fill="white" fill-opacity="0.6" [attr.x]="425">Pkt.</text>
      </ng-container>
    </g>
    <g transform="translate(170, 295)" *ngIf="specialPointsEvent?.type === 'extra_points_event'">
      <text font-family="Open Sans" font-size="195" font-weight="600" fill="white">+{{specialPointsEvent.extra_points}}</text>
      <text font-family="Open Sans" font-size="78" font-weight="400" fill="white" fill-opacity="0.8"
            [attr.x]="222 + (specialPointsEvent.extra_points >= 100 ? 116 : 0) + (specialPointsEvent.extra_points >= 10 ? 116 : 0)">Pkt.
      </text>
    </g>


    <g transform="translate(170, 546)">
      <text font-family="Open Sans" font-size="43" font-weight="600" fill="white">Auf deinen nächsten
        Einkauf{{!specialPointsEvent.extra_points_threshold || specialPointsEvent.type === 'points_multiplier_event' ? '' : ' ab ' + specialPointsEvent.extra_points_threshold + '€'}}</text>

      <g transform="translate(0, 52)" *ngIf="recurring_event?.starts_at !== null">
        <ng-container *ngIf="recurring_event.rule === 'singular'">
          <ng-container *ngIf="isSingleDayEvent()">
            <text font-family="Open Sans" font-size="39" font-weight="300" fill="white">Gültig am {{recurring_event.starts_at | date:'fullDate'}}</text>
            <text transform="translate(0, 52)" font-family="Open Sans" font-size="39" font-weight="300" fill="white">von {{recurring_event.starts_at | date:'H:mm'}}
              bis {{recurring_event.ends_at | date:'H:mm'}} Uhr
            </text>
          </ng-container>
          <ng-container *ngIf="!isSingleDayEvent()">
            <text font-family="Open Sans" font-size="39" font-weight="300" fill="white">Gültig ab {{recurring_event.starts_at | date:'EE, d.M.yyyy H:mm'}} Uhr</text>
            <text transform="translate(0, 52)" font-family="Open Sans" font-size="39" font-weight="300" fill="white">bis {{recurring_event.ends_at | date:'EE, d.M.yyyy H:mm'}}
              Uhr
            </text>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="recurring_event.rule === 'daily'">
          <text font-family="Open Sans" font-size="39" font-weight="300" fill="white">Gültig jeden Tag von {{recurring_event.starts_at | date:'H:mm'}} Uhr
            bis {{recurring_event.ends_at | date:'H:mm'}} Uhr
          </text>
        </ng-container>
        <ng-container *ngIf="recurring_event.rule === 'weekly'">
          <text font-family="Open Sans" font-size="39" font-weight="300" fill="white">Gültig jeden {{getWeekdays()}}</text>
          <text transform="translate(0, 52)" font-family="Open Sans" font-size="39" font-weight="300" fill="white"> von {{recurring_event.starts_at | date:'H:mm'}} Uhr
            bis {{recurring_event.ends_at | date:'H:mm'}} Uhr
          </text>
        </ng-container>
        <ng-container *ngIf="recurring_event.rule === 'monthly'">
          <text font-family="Open Sans" font-size="39" font-weight="300" fill="white">Gültig jeden Monat am {{recurring_event.starts_at | date:'d.'}}</text>
          <text transform="translate(0, 52)" font-family="Open Sans" font-size="39" font-weight="300" fill="white"> von {{recurring_event.starts_at | date:'H:mm'}} Uhr
            bis {{recurring_event.ends_at | date:'H:mm'}} Uhr
          </text>
        </ng-container>
        <ng-container *ngIf="recurring_event.rule === 'yearly'">
          <text font-family="Open Sans" font-size="39" font-weight="300" fill="white">Gültig jedes Jahr am {{recurring_event.starts_at | date:'d. LLLL'}}</text>
          <text transform="translate(0, 52)" font-family="Open Sans" font-size="39" font-weight="300" fill="white"> von {{recurring_event.starts_at | date:'H:mm'}} Uhr
            bis {{recurring_event.ends_at | date:'H:mm'}} Uhr
          </text>
        </ng-container>
      </g>
    </g>
  </svg>
</div>
