<h3>Partnervereinbarungen</h3>
<table class="table table-hover table-responsive">
  <thead>
  <tr>
    <th>Datum</th>
    <th style="width: 67%;">Name</th>
    <th>Download als PDF</th>
  </tr>
  </thead>
  <tbody>
  <tr
      *ngFor="let contract of contracts | paginate: { id: 'server', itemsPerPage: per, currentPage: page, totalItems: count }">
    <td>{{contract.created_at | date: 'dd.MM.yyyy'}}</td>
    <td style="width: 67%;">{{contract.file_name}}</td>
    <td>
      <button class="btn btn-primary btn-sm" (click)="downloadPDF(contract)">Download</button>
    </td>
  </tr>
  </tbody>
</table>
<div *ngIf="contracts === null || contracts.length === 0" class="alert alert-warning" role="alert"
     style="width: 40%; margin: auto;">
  Leider konnten keine Verträge geladen werden. Bitte wende dich an den Mankido Support.
</div>
<pagination-controls [hidden]="contracts.length < 10" (pageChange)="onPage($event)" id="server"
                     style="text-align: center;"></pagination-controls>


