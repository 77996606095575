import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CampaignListComponent} from './campaign-list/campaign-list.component';
import {NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {HeadcontentModule} from '../../shared/head-content/headcontent.module';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [
    CampaignListComponent
  ],
  exports: [
    CampaignListComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    HeadcontentModule,
    NgbPaginationModule,
    RouterModule
  ]
})
export class CampaignListModule { }
