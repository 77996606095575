import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SpecialPointsEvent} from '../special-points-event';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-special-points-events-editor',
  templateUrl: './special-points-event-editor.component.html',
  styleUrls: ['./special-points-event-editor.component.scss']
})
export class SpecialPointsEventEditorComponent implements OnInit {

  @Input()
  specialPointsEvent: SpecialPointsEvent;

  @Input()
  multiDates = false;

  @Input()
  disableRequiresReservation = false;

  @Output()
  specialPointsEventChange: EventEmitter<SpecialPointsEvent> = new EventEmitter<SpecialPointsEvent>();

  @Output()
  onChange: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
    this.specialPointsEventChange.asObservable().pipe(
        debounceTime(0)
    ).subscribe((spe) => {
      // to prevent the onChange event from firing too often only because of a single change
      this.onChange.emit();
    });
  }

  eventHasChanged() {
    if (!this.specialPointsEvent.extra_points_threshold) {
      this.specialPointsEvent.extra_points_threshold = 0;
    }
    this.specialPointsEventChange.emit(this.specialPointsEvent);
  }

  isInFuture(date: Date) {
    return date.getTime() > new Date().getTime();
  }

}
