import {Component, Input, OnInit} from '@angular/core';
import {ObtainReviewEvent} from '../../../obtain-review/obtain-review-event';
import {ContentElement} from '../../../campaigns/models/content-element';

@Component({
  selector: 'app-obtain-review-event-preview',
  templateUrl: './obtain-review-event-preview.component.html',
  styleUrls: ['./obtain-review-event-preview.component.scss']
})
export class ObtainReviewEventPreviewComponent {

  @Input()
  ce: ContentElement;

  @Input()
  forAutomation = false;

  @Input()
  event: ObtainReviewEvent;

  constructor() { }

  getEvent(): ObtainReviewEvent {
    return this.ce ? this.ce.obtain_review_event : this.event;
  }

}
