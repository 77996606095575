import {Component, Input, OnInit} from '@angular/core';
import {Deal} from '../../../deals/deal';
import {ContentElement} from '../../../campaigns/models/content-element';
import {Campaign} from '../../../campaigns/models/campaign';
import {ContentBundle} from '../../../content-bundle/content-bundle';

@Component({
  selector: 'app-deal-preview',
  templateUrl: './deal-preview.component.html',
  styleUrls: ['./deal-preview.component.scss']
})
export class DealPreviewComponent {

  @Input()
  ce: ContentElement;

  @Input()
  contentBundle: ContentBundle;

  @Input()
  deal: Deal;

  constructor() {
  }

  getDeal(): Deal {
    if (this.ce) {
      return this.ce.deal;
    } else {
      return this.deal;
    }
  }

  partnerName(): string {
    if (this.contentBundle && this.contentBundle.author) {
      return this.contentBundle.author.name;
    } else if (this.deal && this.deal.partner) {
      return this.deal.partner.name;
    } else {
      return 'diesem Standort';
    }
  }

}
