import {Prize, PrizeLevel} from './prize';
import {PosExperienceRelation} from '../../pos-experiences/pos_experience';
import {SpecialPointsEvent} from '../../special-points-events/special-points-event';

export class WheelConfiguration {
  theme: WheelTheme;
  jackpot: Prize;
  second: Prize;
  third: Prize;
  consolation: Prize;

  constructor(obj: WheelConfiguration | {} = {}) {
    Object.assign(this as any, obj);
  }

  static getPointsMultiplierEvent(level: PrizeLevel, relations: PosExperienceRelation[]): PosExperienceRelation | null {
    return relations.find(x => x.object_type === 'SpecialPointsEvent'
      && (x.object as SpecialPointsEvent).type === 'points_multiplier_event'
      && x.relation_hint === level);
  }

  static getExtraPointsEvent(level: PrizeLevel, relations: PosExperienceRelation[]): PosExperienceRelation | null {
    return relations.find(x => x.object_type === 'SpecialPointsEvent'
      && (x.object as SpecialPointsEvent).type === 'extra_points_event'
      && x.relation_hint === level);
  }

  static getReward(level: PrizeLevel, relations: PosExperienceRelation[]): PosExperienceRelation | null {
    return relations.find(x => x.object_type === 'Reward'
      && x.relation_hint === level);
  }
}

export type WheelTheme = 'light-theme' | 'dark-theme';
