import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-ipad-preview-container',
  templateUrl: './ipad-preview-container.component.html',
  styleUrls: ['./ipad-preview-container.component.scss']
})
export class IpadPreviewContainerComponent implements AfterViewInit {
  @Input() parent: HTMLDivElement;
  @Input() divisor = 1438; // used to calculate scale

  constructor(private renderer: Renderer2, private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.onResize();
    this.el.nativeElement.style.setProperty('--width-divisor', this.divisor);
  }

  @HostListener('window:resize')
  onResize() {
    // this.renderer.setStyle(this.el, '--available-width', this.parent?.nativeElement?.offsetWidth);
    this.el.nativeElement.style.setProperty('--available-width', this.parent?.offsetWidth);
  }

}
