import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CustomAutomation} from './models/custom-automation';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {filter, map, tap} from 'rxjs/operators';
import {AutomationRetrospect} from './models/automation-retrospect';

@Injectable({
  providedIn: 'root'
})
export class CustomAutomationService {

  private readonly BASE_URL = `${environment.api_base_url}/deal_automations/custom`;

  private hasCustomAutomations$ = new BehaviorSubject<boolean>(null);

  constructor(private http: HttpClient) {
  }

  list(): Observable<CustomAutomation[]> {
    return this.http.get<{ deal_automations: CustomAutomation[] }>(this.BASE_URL).pipe(
      map(res => res.deal_automations.map(automation => new CustomAutomation(automation))),
      tap(automations => this.hasCustomAutomations$.next(automations.length > 0))
    );
  }

  create(type: 'info' | 'deal'): Observable<CustomAutomation> {
    const params = {};
    if (type === 'deal') {
      params['deal_attributes'] = {duration_in_days: 30};
    }
    return this.http.post<{ deal_automation: CustomAutomation }>(this.BASE_URL, {deal_automation: params}).pipe(
      map(res => new CustomAutomation(res.deal_automation)),
      tap(() => this.hasCustomAutomations$.next(true))
    );
  }

  fetch(id: number): Observable<CustomAutomation> {
    return this.http.get<{ deal_automation: CustomAutomation }>(`${this.BASE_URL}/${id}`).pipe(
      map(res => new CustomAutomation(res.deal_automation))
    );
  }

  update(automation: CustomAutomation): Observable<CustomAutomation> {
    return this.http.put<{
      deal_automation: CustomAutomation
    }>(`${this.BASE_URL}/${automation.id}`, {deal_automation: automation.asRequest()}).pipe(
      map(res => new CustomAutomation(res.deal_automation))
    );
  }

  destroy(automation: CustomAutomation): Observable<void> {
    return this.http.delete<void>(`${this.BASE_URL}/${automation.id}`);
  }

  hasCustomAutomations(): Observable<boolean> {
    return this.hasCustomAutomations$.asObservable().pipe(
      filter(hca => {
        if (hca != null) {
          return true;
        }
        this.list().subscribe();
        return false;
      })
    );
  }

  retrospect(id: number): Observable<AutomationRetrospect> {
    return this.http.get<{ retrospect: AutomationRetrospect }>(`${this.BASE_URL}/${id}/retrospect`).pipe(
      map(res => new AutomationRetrospect(res.retrospect))
    );
  }
}
