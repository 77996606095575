import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-status-switch',
  templateUrl: './status-switch.component.html',
  styleUrls: ['./status-switch.component.scss']
})
export class StatusSwitchComponent {
  @Input()
  present: boolean;

  @Input()
  active: boolean;

  @Input()
  disabled: boolean;

  @Input()
  link: string;

  @Input()
  float: 'right' | 'center' = 'right';

  @Output()
  change = new EventEmitter<boolean>();

  constructor() {
  }

  onChange(active) {
    this.change.next(active);
  }

}
