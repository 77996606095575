<div class="row two-titles-headcontent">
  <div class="col-md-6 text-center">
    <h2>Vorschau</h2>
  </div>
  <div class="col-md-6"></div>
</div>

<div class="row">

  <div class="col-md-6 d-flex justify-content-center">
    <app-content-bundle-preview [contentBundle]="campaign.content_bundle" class="w-100" *ngIf="campaign"></app-content-bundle-preview>
  </div>


  <div class="col-md-6">

    <h2 class="verify-question">Zusammenfassung</h2>
    <app-campaign-summary [audienceDefinition]="campaign?.audience_definition"
                          [businesses]="merchant?.available_contexts"></app-campaign-summary>

    <app-campaign-sanity-warnings [campaign]="campaign"></app-campaign-sanity-warnings>

    <h2 class="verify-question">Wann soll deine Kampagne versendet werden?</h2>

    <div class="radio-row" *ngIf="campaign">
      <input id="immediately" type="radio" name="delivery-period" aria-label="send_now"
             [(ngModel)]="campaign.delivery_period" value="immediately" (change)="persistChanges()">
      <label for="immediately">
        <strong>Sofort</strong>
        <p class="text-help" *ngIf="!canSendImmediately()">Der Kampagnenstart muss in der Zukunft liegen. Bitte gehe zurück und bearbeite den Startzeitpunkt.<br/></p>
      </label>
    </div>

    <div class="radio-row" *ngIf="campaign">
      <input id="scheduledsend" type="radio" name="delivery-period" aria-label="send_now"
             [(ngModel)]="campaign.delivery_period" value="scheduledsend" (change)="persistChanges()" [disabled]="!canScheduledsendBeActivated()">
      <label for="scheduledsend" [class.disabled]="!canScheduledsendBeActivated()">
        <strong>Kampagne planen</strong><br/>
        <span *ngIf="canScheduledsendBeActivated()">Lege den Zeitpunkt fest wann die Kampagne versendet werden soll<br/></span>
        <span *ngIf="!canScheduledsendBeActivated()" class="disabled">Die Kampagne kann nicht zu einem späteren Zeitpunkt gesendet werden da sie in weniger als drei Tagen startet</span>
<!--        <span *ngIf="!canScheduledsendBeActivated() && campaign.campaign_goal === 'deal'" class="disabled">Die Kampagne kann nicht zu einem späteren Zeitpunkt gesendet werden da sie in weniger als drei Tagen {{ isInFuture(campaign.deal.starts_at) ? 'startet' : 'endet'}}</span>-->
        <div class="d-flex flex-column mt-0" style="padding-bottom: 10rem;"
             [class.hidden-xs-up]="campaign.delivery_period !== 'scheduledsend'">
          <div class="d-flex align-items-center justify-content-start mt-0">
            <app-date-time-picker [(datetime)]="campaign.scheduledsend_at" (change)="persistChanges()"
                                  [minuteStep]="15" [valid]="campaign.scheduledsend_at === null || campaign.isScheduledsendValid()"></app-date-time-picker>
            <span class="ml-1 mt-2 align-self-center d-none d-md-inline">Uhr</span>
          </div>
          <p class="text-help" style="margin-top: -1em;" [hidden]="campaign.scheduledsend_at === null || campaign.isScheduledsendValid() || !isScheduledsendInFuture()">
            Die Kampagne muss mindestens ein Tag vor Beginn gesendet werden
          </p>
          <p class="text-help" style="margin-top: -1em;" [hidden]="isScheduledsendInFuture()">
            Das gewünschte Versanddatum muss in der Zukunft liegen
          </p>
        </div>
      </label>
    </div>

  </div>

</div>

<app-progressbar [campaign]="campaign" [state]="'verify'"></app-progressbar>
