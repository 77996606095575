import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {interval, Observable, ReplaySubject} from 'rxjs';
import {filter, map, skip, switchMap, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ReleaseMonitorService {

  private timer$ = interval(60 * 1000);
  private updated$ = new ReplaySubject<void>();

  constructor(private httpClient: HttpClient, private toastrService: ToastrService) {
    this.timer$.pipe(
      skip(1),
      takeUntil(this.updated$),
      switchMap(_ => this.checkVersion()),
      filter(version => version !== environment.release),
      tap(version => console.log('App has been updated', environment.release, '->', version)),
      tap(_ => {
        this.toastrService.info(
          'Neue App Version verfügbar! Lade die Seite neu, um die neuesten Verbesserungen zu erhalten.',
          'Update verfügbar',
          {timeOut: 0}
        );
      }),
      tap(_ => this.updated$.next())
    ).subscribe();
  }

  private checkVersion(): Observable<string> {
    const headers = {
      'Cache-Control': 'no-store'
    };
    return this.httpClient.get<{ version: string }>('/latest-version.json', {headers}).pipe(
      map(res => res.version)
    );
  }
}
