<h1 class="mt-5 text-center">POS Experiences</h1>
<p class="mt-1 text-center" style="max-width: 40rem; margin-left: auto; margin-right: auto">
  Nutze das Mankido POS Terminal für interaktive Erlebnisse an deinem Point of Sale
</p>

<app-cards class="pb-0">
  <div class="card card-flex card-link text-center">
    <div class="card-block">
      <img src="assets/img/pos_experiences/advent_calendar.svg" class="card-img-top card-icon" alt=""/>
      <h4 class="card-title">Adventskalender</h4>
      <p class="card-text">Täglich ein Türchen mit Überraschung für deine Kunden.</p>
      <app-pos-experience-switch [type]="types.advent_calendar"></app-pos-experience-switch>
    </div>
  </div>

  <div class="card card-flex card-link text-center" *ngIf="env.features.special_points_events">
    <div class="card-block">
      <img src="assets/img/multiplicator.svg" class="card-img-top card-icon" alt=""/>
      <h4 class="card-title">Spezial-Punkte Events</h4>
      <p class="card-text">Verwalte regelmäßige Extra-Punkte und Doppel-Punkte Events</p>
      <app-pos-experience-switch [type]="types.special_points_event"></app-pos-experience-switch>
    </div>
  </div>

  <div class="card card-flex card-link text-center" *ngIf="env.features.special_points_events">
    <div class="card-block">
      <img src="assets/img/pos_experiences/wheel-of-luck.svg" class="card-img-top card-icon" alt=""/>
      <h4 class="card-title">Glücksrad</h4>
      <p class="card-text">Lass deine Kunden am Mankido Glücksrad drehen</p>
      <app-pos-experience-switch [type]="types.wheel_of_luck"></app-pos-experience-switch>
    </div>
  </div>

  <div class="card card-flex card-link text-center d-none">
    <div class="card-block">
      <img src="assets/img/rewards.svg" class="card-img-top card-icon" alt=""/>
      <h4 class="card-title">Bald verfügbar</h4>
      <p class="card-text">Viele weitere In-Store Erlebnisse sind bald verfügbar</p>
      <span class="btn btn-primary">Coming soon!</span>
    </div>
  </div>
</app-cards>
