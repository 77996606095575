<ng-container *ngIf="specialPointsEvent">
  <h6 style="font-weight: 600" class="mb-1">Option wählen:</h6>
  <div ngbRadioGroup class="d-flex flex-column flex-md-row justify-content-md-start ml-1 ml-md-0">
    <div>
      <div class="radio-row">
        <input type="radio" id="points-multiplier-event" name="special-points-event-type"
               [(ngModel)]="specialPointsEvent.type" value="points_multiplier_event" (change)="eventHasChanged()">
        <label for="points-multiplier-event">
          <strong>Punkte-Multiplikator</strong>
          <br/>
          <small>z.B. 3-Fach Punkte auf deinen Einkauf</small>
        </label>
      </div>
    </div>

    <div class="ml-0 ml-md-2">
      <div class="radio-row">
        <input type="radio" id="extra-points-event" name="special-points-event-type"
               [(ngModel)]="specialPointsEvent.type" value="extra_points_event" (change)="eventHasChanged()">
        <label for="extra-points-event" class="form-check-label">
          <strong>Extra Punkte</strong>
          <br/>
          <small>z.B. +20 Punkte auf deinen Einkauf ab 15€</small>
        </label>
      </div>
    </div>
  </div>

  <ng-container *ngIf="specialPointsEvent.type === 'points_multiplier_event'">
    <div ngbRadioGroup class="btn-group-toggle">
      <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="specialPointsEvent.multiplier === 1.1">
        <input name="multiplier" ngbButton type="radio" [value]="1.1" [(ngModel)]="specialPointsEvent.multiplier" (change)="eventHasChanged()" autocomplete="off">+10%
      </label>
      <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="specialPointsEvent.multiplier === 1.2">
        <input name="multiplier" ngbButton type="radio" [value]="1.2" [(ngModel)]="specialPointsEvent.multiplier" (change)="eventHasChanged()">+20%
      </label>
      <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="specialPointsEvent.multiplier === 1.25">
        <input name="multiplier" ngbButton type="radio" [value]="1.25" [(ngModel)]="specialPointsEvent.multiplier" (change)="eventHasChanged()">+25%
      </label>
      <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="specialPointsEvent.multiplier === 1.5">
        <input name="multiplier" ngbButton type="radio" [value]="1.5" [(ngModel)]="specialPointsEvent.multiplier" (change)="eventHasChanged()">+50%
      </label>
      <br class="d-block d-md-none"/>
      <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="specialPointsEvent.multiplier === 2">
        <input name="multiplier" ngbButton type="radio" [value]="2" [(ngModel)]="specialPointsEvent.multiplier" (change)="eventHasChanged()">2x
      </label>
      <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="specialPointsEvent.multiplier === 3">
        <input name="multiplier" ngbButton type="radio" [value]="3" [(ngModel)]="specialPointsEvent.multiplier" (change)="eventHasChanged()">3x
      </label>
      <label ngbButtonLabel class="btn btn-outline-primary multiplier-label" [class.active]="specialPointsEvent.multiplier === 5">
        <input name="multiplier" ngbButton type="radio" [value]="5" [(ngModel)]="specialPointsEvent.multiplier" (change)="eventHasChanged()">5x
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="specialPointsEvent.type === 'extra_points_event'">
    <div class="form-inline d-flex flex-column flex-md-row align-items-center">
      <div class="d-flex align-items-center">
        <input type="number" step="1" min="0" max="9999" class="form-control mr-1" style="width: 5em;" placeholder="20"
               [(ngModel)]="specialPointsEvent.extra_points" (change)="eventHasChanged()">
        <span class="mr-2 mr-md-1">Extra-Punkte auf deinen Einkauf ab<span class="d-inline d-md-none">:</span> </span>
      </div>
      <div class="input-group">
        <input type="number" step="1" min="0" max="9999" class="form-control" style="width: 5em;" placeholder="15"
               [(ngModel)]="specialPointsEvent.extra_points_threshold" (change)="eventHasChanged()">
        <div class="input-group-append">
          <div class="input-group-text">€</div>
        </div>
      </div>
    </div>
  </ng-container>

  <hr>

  <h6 style="font-weight: 600;" class="mb-1">Dauer der Aktion wählen:</h6>
  <div style="width: max-content;" *ngIf="!multiDates">
    <div class="form-inline justify-content-between">
      <span class="mr-1">Startzeitpunkt</span>
      <app-date-time-picker [(datetime)]="specialPointsEvent.recurring_events[0].starts_at" [seconds]="false"
                            [minuteStep]="15" (change)="eventHasChanged()"
                            [valid]="isInFuture(specialPointsEvent.recurring_events[0].starts_at)"></app-date-time-picker>
    </div>
    <p class="text-help" style="margin-top: -1em;" [hidden]="isInFuture(specialPointsEvent.recurring_events[0].starts_at)">
      Der Startzeitpunkt muss in der Zukunft liegen
    </p>
    <div class="form-inline justify-content-between">
      <span class="mr-1">Endzeitpunkt</span>
      <app-date-time-picker [(datetime)]="specialPointsEvent.recurring_events[0].ends_at" [seconds]="false"
                            [minuteStep]="15" (change)="eventHasChanged()"
                            [valid]="isInFuture(specialPointsEvent.recurring_events[0].ends_at)"></app-date-time-picker>
    </div>
    <p class="text-help" style="margin-top: -1em;" [hidden]="isInFuture(specialPointsEvent.recurring_events[0].ends_at)">
      Der Endzeitpunkt muss in der Zukunft liegen
    </p>
  </div>
  <ng-container *ngIf="multiDates">
    <app-recurring-event-picker [(recurringEvents)]="specialPointsEvent.recurring_events" [rules]="['daily', 'weekly']"
                                (change)="eventHasChanged()"></app-recurring-event-picker>
  </ng-container>


  <ng-container
    *ngIf="specialPointsEvent.recurring_events?.length > 0 && specialPointsEvent.recurring_events[0].rule === 'singular'; else infos">
    <hr>


    <h6 style="font-weight: 600;" class="mb-1">Bedingungen für Aktionszeitraum wählen:</h6>
    <p *ngIf="disableRequiresReservation">Da eine spezielle Kundengruppe ausgewählt wurde, ist diese Option nicht verfügbar. Falls das Event für jeden Besucher in einer oder allen Filialen zur Verfügung stehen soll, wähle bei <em>Zielgruppe</em> unter <em>Kundengruppe</em> einfach <em>Alle Kunden</em> aus.</p>
    <div ngbRadioGroup [ngModel]="specialPointsEvent.requires_reservation" name="requires-reservation"
         class="d-flex justify-content-start" [class.disabled]="disableRequiresReservation">
      <div>
        <div class="radio-row">
          <input type="radio" id="requires-reservation" name="requires-reservation"
                 [(ngModel)]="specialPointsEvent.requires_reservation" [value]="true" (change)="eventHasChanged()">
          <label for="requires-reservation" class="">
            <strong>Nur einlösbar mit Reservierung</strong>
            <br/>
            <small>Die Spezial-Punkte stehen nur den bei <em>Zielgruppe</em> ausgewählten Kunden zur Verfügung und
              muss von den Kunden zunächst reserviert werden.</small>
          </label>
        </div>
      </div>

      <div class="ml-2">
        <div class="radio-row">
          <input type="radio" id="without-reservation" name="requires-reservation"
                 [(ngModel)]="specialPointsEvent.requires_reservation" [value]="false"
                 (change)="eventHasChanged()">
          <label for="without-reservation">
            <strong>Automatisch beim Besuch</strong>
            <br/>
            <small>Bestands- und Neukunden erhalten automatisch beim nächsten Einkauf im Aktionszeitraum Spezial-Punkte</small>
          </label>
        </div>
      </div>
    </div>


    <h6 style="font-weight: 600;" class="mb-1 mt-2">Wie häufig darf der Kunde das Angebot einlösen?</h6>
    <div ngbRadioGroup [ngModel]="specialPointsEvent.multi_usable" name="multi-usable"
         class="d-flex justify-content-start">
      <div style="width: 100%;">
        <div class="radio-row">
          <input type="radio" id="multi-usable" name="multi-usable"
                 [(ngModel)]="specialPointsEvent.multi_usable" [value]="true" (change)="eventHasChanged()">
          <label for="multi-usable">
            <strong>Mehrfach</strong>
            <br/>
            <small *ngIf="specialPointsEvent.type === 'extra_points_event'">Der Kunde kann im Aktionszeitraum jeweils
              einmal pro Tag die Extra-Punkte erhalten</small>
            <small *ngIf="specialPointsEvent.type === 'points_multiplier_event'">Der Kunde erhält im gesamten
              Aktionszeitraum für jeden Einkauf Mehrfach-Punkte</small>
          </label>
        </div>
      </div>

      <div class="ml-2" style="width: 100%;">
        <div class="radio-row">
          <input type="radio" id="not-multi-usable" name="multi-usable"
                 [(ngModel)]="specialPointsEvent.multi_usable" [value]="false"
                 (change)="eventHasChanged()">
          <label for="not-multi-usable" class="form-check-label">
            <strong>Einfach</strong>
            <br/>
            <small *ngIf="specialPointsEvent.type === 'extra_points_event'">Der Kunde erhält im Aktionszeitraum ein
              einziges Mal die Extra-Punkte</small>
            <small *ngIf="specialPointsEvent.type === 'points_multiplier_event'">Der Kunde erhält nur am Tag der
              ersten Nutzung Mehrfach-Punkte auf jede Transaktion</small>
          </label>
        </div>
      </div>
    </div>

  </ng-container>

  <ng-template #infos>
    <hr>
    <h6 style="font-weight: 600;" class="mb-1">Hinweise:</h6>
    Das Event steht allen Kunden zur Verfügung und muss nicht vorab reserviert werden.
    <span *ngIf="specialPointsEvent.type === 'extra_points_event'">
      Der Kunde kann im Aktionszeitraum jeweils einmal pro Tag die Extra-Punkte erhalten.
    </span>
    <span *ngIf="specialPointsEvent.type === 'points_multiplier_event'">
      Der Kunde erhält im gesamten Aktionszeitraum für jeden Einkauf Mehrfach-Punkte.
    </span>
  </ng-template>
</ng-container>
