import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {SpecialPointsEvent} from './special-points-event';
import {Params} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SpecialPointsEventService {
  public URL: string = environment.api_base_url + 'special_points_events';

  constructor(private http: HttpClient) {
  }

  fetch(id: string): Observable<SpecialPointsEvent> {
    return this.http.get<SpecialPointsEvent>(`${this.URL}/${id}`)
        .pipe(
            map(value => new SpecialPointsEvent(value)),
            catchError(this.handleError)
        );
  }

  fetchAll(): Observable<SpecialPointsEvent[]> {
    return this.http.get(this.URL).pipe(
        map((res: SpecialPointsEvent[]) => res.map(event => new SpecialPointsEvent(event))), // TODO does this work?
        catchError(this.handleError)
    );
  }

  create(specialPointsEvent: SpecialPointsEvent): Observable<SpecialPointsEvent> {
    return this.http.post<SpecialPointsEvent>(this.URL, this.prepareData(specialPointsEvent))
        .pipe(
            map(value => new SpecialPointsEvent(value)),
            catchError(this.handleError)
        );
  }

  update(specialPointsEvent: SpecialPointsEvent): Observable<SpecialPointsEvent> {
    return this.http.put<SpecialPointsEvent>(`${this.URL}/${specialPointsEvent.id}`, this.prepareData(specialPointsEvent))
        .pipe(
            map(value => new SpecialPointsEvent(value)),
            catchError(this.handleError)
        );
  }

  // TODO fix return type
  destroy(specialPointsEvent: SpecialPointsEvent): Observable<any> {
    return this.http.delete(`${this.URL}/${specialPointsEvent.id}`).pipe(
        catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    const errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }

  private prepareData(specialPointsEvent: SpecialPointsEvent): object {
    const params: Params = {special_points_event: {...specialPointsEvent}};
    params.special_points_event.recurring_events_attributes = specialPointsEvent.recurring_events;
    delete params.special_points_event.recurring_events;
    return params;
  }

}
