<div id="edit-container" *ngIf="showEdit">
  <div [ngSwitch]="type">
    <app-advent-calendar [posExperience]="posExperience" (save)="onSave()"
                         *ngSwitchCase="PosExperienceType.advent_calendar"></app-advent-calendar>
    <app-special-points-event-list *ngSwitchCase="PosExperienceType.special_points_event">
    </app-special-points-event-list>
    <app-wheel-of-luck-editor [posExperience]="posExperience"
                              *ngSwitchCase="PosExperienceType.wheel_of_luck"></app-wheel-of-luck-editor>
  </div>
</div>

<div id="info-container" *ngIf="!showEdit">
  <div [ngSwitch]="type">
    <div *ngSwitchCase="PosExperienceType.advent_calendar" style="text-align: center;">
      <img class="img-fluid" src="assets/img/pos_experiences/advent_calendar.jpg"
           style="max-width: 400px; margin: 50px 0 0 0;">
      <h1>Adventskalender</h1>
      <p class="lead" style="margin: 30px 50px;">
        Deine Kunden können vom 1. bis zum 24. Dezember jeden Tag ein virtuelles Türchen am iPad öffnen. Verschenke
        Extra Punkte oder Sachprämien. Steigere die Kundenbindung durch spannende In-Store-Erlebnisse.
      </p>

      <p>
        <button class="btn btn-primary" (click)="onStart()">Direkt loslegen</button>
        <br>
      </p>

      <h2 class="mt-5 mb-2 d-none d-md-block">So funktioniert der Adventskalender</h2>

      <iframe width="700" height="457" class="d-none d-md-block"
              src="https://www.youtube.com/embed/uERvd1pqrp4?&controls=1&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3&color=white&vq=hd1080"
              frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>

      <div style="background: #fafafa; padding: 100px 3000px;margin: 100px -3000px 0;">
        <h3 class="mb-2">Es gibt zwei Arten von Überraschungen, die du für die 24 Türchen verwenden kannst:</h3>

        <div class="row mb-3">
          <div class="col">
            <p class="mb-1 lead">Extra-Punkte</p>
            <img src="assets/img/pos_experiences/advent_calendar_example2.png" class="img-fluid example" alt="">
            <p class="mt-2">Die Exta-Punkte werden dem Kunden <i>automatisch</i> beim Öffnen des Türchens ohne weiteren
              Aufwand durch das Verkaufspersonal gutgeschrieben.</p>
          </div>
          <div class="col">
            <p class="mb-1 lead">Freitext Prämie</p>
            <img src="assets/img/pos_experiences/advent_calendar_example3.png" class="img-fluid example" alt="">
            <p class="mt-2">Der Freitext erlaubt es, völlig frei eine Überraschung anzubieten - dies kann z.B. ein
              Rabatt oder ein kleines Geschenk sein.</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="PosExperienceType.special_points_event" style="text-align: center;">
      <app-special-points-events-editor></app-special-points-events-editor>
    </div>
    <ng-container *ngSwitchCase="PosExperienceType.wheel_of_luck">
      <app-wheel-of-luck-info (start)="onStart()"></app-wheel-of-luck-info>
    </ng-container>
  </div>
</div>
