import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ContentBundle} from '../content-bundle';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentBundleService {
  CONTENT_BUNDLE_URL: string = environment.api_base_url + 'content_bundles';

  constructor(private httpClient: HttpClient) {
  }

  public fetch(id: number): Observable<ContentBundle> {
    return this.httpClient.get<{ content_bundle: ContentBundle }>(`${this.CONTENT_BUNDLE_URL}/${id}`).pipe(
      map(data => {
        return new ContentBundle(data.content_bundle);
      }),
      catchError(this.handleError)
    );
  }

  public update(contentBundle: ContentBundle): Observable<ContentBundle> {
    return this.httpClient.put<{ content_bundle: ContentBundle }>(
      `${this.CONTENT_BUNDLE_URL}/${contentBundle.id}`,
      {content_bundle: contentBundle.asRequest()}
    ).pipe(
      map(data => {
        return new ContentBundle(data.content_bundle);
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(error);
  }
}
