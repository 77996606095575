import {BehaviorSubject, Observable, Subject, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {Partner} from './partner';
import {environment} from '../../environments/environment';
import {AutomationStatus} from '../dashboard/automations/automation.status';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  public PARTNERS_URL: string = environment.api_base_url + 'partners';

  currentPartner: Subject<Partner> = new BehaviorSubject<Partner>(new Partner({}));
  automationStatus: BehaviorSubject<AutomationStatus> = new BehaviorSubject<AutomationStatus>(undefined);
  automationStatusLoaded = false;

  constructor(private http: HttpClient) {
  }

  public setCurrentPartner(newPartner: Partner): void {
    this.currentPartner.next(newPartner);
  }

  fetch(id: number): Observable<Partner> {
    return this.http.get<Partner>(`${this.PARTNERS_URL}/${id}`)
      .pipe(
        map(res => new Partner(res)),
        catchError(this.handleError)
      );
  }

  update(id: number, partner: Partner) {
    const param: { partner: Partner } = {partner: partner};
    return this.http.put<Partner>(`${this.PARTNERS_URL}/${id}`, param)
      .pipe(
        map(res => new Partner(res)),
        catchError(this.handleError)
      );
  }

  updateTerminals(id: number) {
    return this.http.post(`${this.PARTNERS_URL}/${id}/update_terminals`, {}).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }


}
