import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WheelOfLuckComponent} from '../wheel-of-luck/wheel-of-luck.component';
import {take} from 'rxjs/operators';
import {GameAnimation} from '../animations/game-animation';
import {animate, animateChild, group, keyframes, query, state, style, transition, trigger} from '@angular/animations';
import {Prize} from '../models/prize';
import {PosExperience, PosExperienceRelation} from '../../pos-experiences/pos_experience';
import {SpecialPointsEvent} from '../../special-points-events/special-points-event';
import {Reward} from '../../loyalty/rewards/reward';

@Component({
  selector: 'app-wheel-of-luck-game',
  templateUrl: './wheel-of-luck-game.component.html',
  styleUrls: ['./wheel-of-luck-game.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state('void', style({transform: 'scale(0)', opacity: 0})),
      state('*', style({transform: 'scale(1)', opacity: 1})),
      transition('void => *', [
        group([
          query('@scaleIn', animateChild()),
          animate('300ms', keyframes([
            style({transform: 'scale(0)', opacity: 0, offset: 0}),
            style({transform: 'scale(1.4)', opacity: 1, offset: 0.8}),
            style({transform: 'scale(1)', opacity: 1, offset: 1})
          ]))
        ])
      ])
    ])
  ]
})
export class WheelOfLuckGameComponent {

  @Input()
  wheelComponent: WheelOfLuckComponent;

  @Input()
  posExperience: PosExperience;

  winningRelation: PosExperienceRelation = null;

  @Output()
  winning: EventEmitter<PosExperienceRelation> = new EventEmitter<PosExperienceRelation>();

  @Output()
  nextClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  onClick() {
    if (this.wheelComponent != null) {
      // return this.animations = [{duration: 60, easing: (x) => x, distance: 360 * 22}];
      const prize = this.calculatePrize();
      this.wheelComponent.animations = GameAnimation.createTurnAnimations(prize[1], this.wheelComponent.getWheelAngle());
      this.wheelComponent.animationFinished.pipe(take(1)).subscribe(($event) => {
        const needleEasingAnimations = GameAnimation.createNeedleEasingAnimation($event.wheelAngle, $event.needleAngle);
        if (needleEasingAnimations == null || needleEasingAnimations.length === 0) {
          this.winningRelation = this.getPrizeRelation(prize[0]);
          this.winning.emit(this.winningRelation);
        } else {
          this.wheelComponent.animations = needleEasingAnimations;
          this.wheelComponent.animationFinished.pipe(take(1)).subscribe((event) => {
            this.winningRelation = this.getPrizeRelation(prize[0]);
            this.winning.emit(this.winningRelation);
          });
        }
      });
    }
  }

  reset() {
    this.winningRelation = null;
  }

  getRelationObject(type: 'extra_points_event' | 'points_multiplier_event' | 'reward'): SpecialPointsEvent | Reward {
    return this.posExperience.relations.find(x => x.relation_hint === 'jackpot'
      && ((type !== 'reward' && x.object_type === 'SpecialPointsEvent' && (x.object as SpecialPointsEvent).type === type)
        || (type === 'reward' && x.object_type === 'Reward'))).object;
  }

  private calculatePrize(): [Prize, number] {
    const random = Math.random() * 100;
    if (this.posExperience.config.jackpot.odds >= random) {
      return [this.posExperience.config.jackpot, 0];
    } else if ((this.posExperience.config.jackpot.odds + this.posExperience.config.second.odds) >= random) {
      return [this.posExperience.config.second, 4];
    } else if ((this.posExperience.config.jackpot.odds + this.posExperience.config.second.odds + this.posExperience.config.third.odds) >= random) {
      return [this.posExperience.config.third, this.getRandomIndex([2, 6])];
    } else {
      return [this.posExperience.config.consolation, this.getRandomIndex([1, 3, 5, 7])];
    }
  }

  private getRandomIndex(array: number[]) {
    return array[Math.floor(Math.random() * array.length)];
  }

  getPrizeRelation(prize: Prize) {
    const r = this.posExperience.relations.find(x => x.relation_hint === prize.level
      && ((prize.type === 'reward' && x.object_type === 'Reward') || (prize.type !== 'reward' && x.object_type === 'SpecialPointsEvent' && (x.object as any).type === prize.type))
    );
    return r;
  }

}
